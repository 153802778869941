import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Chip } from "@mui/material";
import classes from "./home.module.css";
import classesOne from "../property/property.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ContactIcon, HeartIcon } from "../../Assets/Icons/icons";
import "./home.css";
import { formatNumber } from "../../Utilities/commonFunctions";
import { useNavigate } from "react-router-dom";
import ContactPop from "../popup/ContactPop";
import ContactOwnerPop from "../popup/ContactOwnerPop";
import { addWishlist } from "../../Redux/Actions/userActions";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function PropertySlider(props) {
  const { properties, title,handleWishlist } = props;
  const [contactPopup, setContactPopup] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });


  const navigate = useNavigate();

  function handleSelectCover(photos) {
    let property_photos = photos;
    let cover_img = "";

    property_photos.forEach((val) => {
      if (val?.is_cover_image) {
        cover_img = val?.image;
      } else {
        if (cover_img === "") {
          cover_img = val?.image;
        }
      }
    });

    return `${process.env.REACT_APP_API_URL}/${cover_img}`;
  }

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  const handleGetContact = (e) => {
    const { name, value } = e.target;
    setGetContact({ ...getContact, [name]: value });
  };

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({...getContact,name:Con_details?.name,phone:Con_details?.phone,email:Con_details?.email})
    }
  }, []);


  return (
    <>
      <Box my={3}>
        <Carousel
          responsive={responsive}
          autoPlaySpeed={1500}
          infinite={true}
          arrows={true}
          className="carousel-wrapper"
        >
          {Array.isArray(properties) &&
            properties.map(
              (datas, index) =>
                (title === datas.kind_of_property || title === "all") && (
                  <Box className={classes.single_property_view}>
                    <Box
                      className={classes.single_property_view_img}
                      onClick={() => navigate(`/singleproperty/${datas._id}`)}
                    >
                      <img
                        src={
                          datas?.photos[0]?.image
                            ? handleSelectCover(datas.photos)
                            : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                        }
                        alt="image"
                        height={"100%"}
                        width={"100%"}
                      />
                    </Box>
                    <Box p={2}>
                      <Typography className={classes.single_property_title}>
                        {datas.created_user?.name}
                      </Typography>
                      <Typography className={classes.single_property_location}>
                        {datas?.location}
                      </Typography>
                      <Typography
                        className={classes.single_property_highlights}
                      >
                        Highlights :
                        {datas.other_features.slice(0, 2).map((el) => (
                          <Chip label={el} />
                        ))}
                        <span className={classes.single_property_highlights}>
                          {datas.other_features.length - 2 > 0
                            ? `+${datas.other_features.length - 2}`
                            : ""}
                        </span>
                      </Typography>
                      <Typography className={classes.single_property_feet}>
                        {datas?.area_of_property} Sq.Ft.
                      </Typography>
                      <Box className="d-flex" gap={"15px"}>
                        <Typography className={classes.single_property_price}>
                          ₹ {formatNumber(datas.price?.expected_price)}{" "}
                          <span>Onwards</span>
                        </Typography>
                        <Button
                          className={classes.contact_btns}
                          onClick={() => {
                            setContactPopupDetails({
                              phone: datas?.created_user?.phone,
                              email: datas?.created_user?.email,
                              name: datas?.created_user?.name,
                            });
                            setContactPopup(true);
                          }}
                        >
                          <ContactIcon /> Call
                        </Button>
                      </Box>
                    </Box>
                    <Typography
                      className={`${datas?.likedByUser && classes.active_heart} ${classes.slider_heart_icon}`}
                      onClick={()=>handleWishlist(datas._id,title)}
                    >
                      <HeartIcon />
                    </Typography>
                  </Box>
                )
              // </Grid>
            )}
        </Carousel>
        {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
        <ContactPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          // handleGetContact={handleGetContact}
          // getContact={getContact}
        />
      </Box>
    </>
  );
}

export default PropertySlider;
