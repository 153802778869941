import React, { useState } from "react";
import classes from "./auth.module.css";
import { Button, Card, Typography, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router";
import { CloseIcon, DownChevorletIcon } from "../../Assets/Icons/icons";
import { ValidatorForm } from "react-material-ui-form-validator";
import DefaultLayout from "./defaultLayout";
import { StyledInput } from "../../Components/commonInputFeild";
import { Numeric } from "../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, pageLoader } from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";

const Login = () => {
  const LoginDiv = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorAlert, setErrorAlert] = useState();
    const { ln, error } = useSelector((state) => state.userLogin);
    const [phone, setPhone] = useState("");
    const [isInputFocused, setInputFocused] = useState(false);
    
    const handleFocus = () => {
      setInputFocused(true);
    };

    const closeAlert = () => {
      setErrorAlert(false);
    };

    const handleBlur = () => {
      if (phone === "") {
        setInputFocused(false);
      }
    };

    const handleChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= 10) {
        setPhone(inputValue);
      }
    };

    const handleSubmit = () => {
      const userEntry = {
        phone: phone,
      };
      dispatch(pageLoader(true));
      dispatch(loginUser(userEntry, navigate, setErrorAlert));
    };

    return (
      <Card className={classes.registerCard}>
        <Typography variant="h5" className={classes.registerHeader}>
          {ln && ln["login"] ? ln["login"] : "Login"}
        </Typography>
        <Button
          className={classes.closeButton}
          variant="text"
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </Button>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={classes.SignUpForm}
        >
          <StyledInput
            label={
              ln && ln["enter_mobile_number"]
                ? ln["enter_mobile_number"]
                : "Enter Mobile Number"
            }
            variant="standard"
            fullWidth
            id="emailInput"
            placeholder=""
            className={`${classes.formInputFeild} ${
              isInputFocused && classes.FocusedClass
            }`}
            value={phone}
            size="normal"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: isInputFocused && ( // Conditionally render input adornment
                <InputAdornment position="start">
                  <Typography className={classes.InputAdornmentText}>
                    +91
                  </Typography>
                  <DownChevorletIcon />
                </InputAdornment>
              ),
            }}
            // inputProps={{ maxLength: 10 }}
            onKeyPress={(e) => {
              Numeric(e);
            }}
            validators={["required"]} // Add this line
            errorMessages={["Mobile Number is required"]}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={phone.length !== 10}
            fullWidth
            className={`mt-4 ${classes.continueBtn} ${
              phone.length !== 10 && classes.disabledBtn
            }`}
          >
            {ln && ln["send_otp"] ? ln["send_otp"] : "Send OTP"}
          </Button>
        </ValidatorForm>
        <Typography className={classes.NoteText}>
          {ln && ln["didnt_have_account"]
            ? ln["didnt_have_account"]
            : "Didn't Have an Account?"}{" "}
          <span onClick={() => navigate("/register")}>
            {ln && ln["register"] ? ln["register"] : "register"}
          </span>
        </Typography>
        {errorAlert && error && (
          <AlertBox type={"error"} message={error} stateName={closeAlert} />
        )}
      </Card>
    );
  };

  return <DefaultLayout children={<LoginDiv />} />;
};

export default Login;
