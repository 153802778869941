import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import GuestHeaders from "./headers";
import classes from "./layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getLanguageData, pageLoader } from "../Redux/Actions/userActions";

const GuestLayout = () => {
  const { loading, ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;

  
  useEffect(()=>{
    let user_path = window.location.pathname;
      if (_token  && !user_path.includes("language") && !user_path.includes("personal") ) {
        navigate("/homepage")
      } 
  },[ window.location.pathname])

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      navigate("/alllistings");
    }
    // if (!localStorage.getItem("language")) {
    //   navigate("/language-select");
    // }
  }, []);

  useEffect(() => {
    const lan = JSON.parse(localStorage.getItem("language"));
    // dispatch(pageLoader(true));
    dispatch(getLanguageData(lan?.code ? lan.code : "en", setErrorAlert));
  }, [window.location.pathname]);

  return (
    <div style={{height:"100%"}} >
      <div className={classes.guestHeaderParentDiv}>
        <GuestHeaders />
      </div>
      <div className={classes.guestBodyParentDiv}>
        <Outlet />
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default GuestLayout;
