import {
  BackIcon,
  DeleteIconBlack,
  WhatsAppIcon,
} from "../../Assets/Icons/icons";
import AddImage from "../../Assets/Images/add-image.png";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import classes1 from "../Auth/auth.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Numeric, numberWithCommas } from "../../Utilities/commonFunctions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createDraftProperty, createProperty, errorAlertAction, postPropertyDetails, updateProperty,addPhoto } from "../../Redux/Actions/userActions";

let _property = [
  { text: "Freehold", value: "freehold" },
  { text: "Leasehold", value: "leasehold" },
  { text: "Co-operative society", value: "co_operative_society" },
  { text: "Power of atony", value: "power_of_atony" },
];

let preLeased = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

function PropertyPricingDetails() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const AddPricingDiv = () => {
    const [inputs, setInputs] = useState({
      expected_price: "",
      pricing_details: [],
      kinf_of_property: "",
      about_property: "",
      approved_authority:"",
      pre_leased:""
    });
    const [uploadedImgs, setUploadedImgs] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { propertyData,ln } = useSelector((state) => state.userLogin);
    const userValues = JSON.parse(sessionStorage.getItem("user_values"));
    let _edit = sessionStorage.getItem("edit");
    let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
    let _docs = JSON.parse(sessionStorage.getItem("converted_doc_imgs"));
    let my_res = JSON.parse(sessionStorage.getItem("property_info"));
    

    function handleNext() {
      if (inputs.pricing_details.length === 0 || inputs.kinf_of_property === "") {
        localStorage.setItem("error","2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if ( (propertyData?.kind_of_property == "661cc347267fb2f57d21fb9a" || propertyData?.kind_of_property == "661cc398267fb2f57d21fba0") && inputs.pre_leased === "") {
        localStorage.setItem("error","2");
        dispatch(errorAlertAction("Please select all fields"));
      }  else {
        const body ={
          ownership: inputs.kinf_of_property,
          price: {
              expected_price: inputs.expected_price,
              pricing_details: inputs.pricing_details
          },
          property_details: inputs.about_property,
          approved_authority:inputs.approved_authority,
          pre_released_pre_rented:inputs.pre_leased
        };
        let price_convert = body["price"].expected_price?.replaceAll(",","");
        body["price"].expected_price = price_convert;
        let my_res = JSON.parse(sessionStorage.getItem("property_info"));
        
        sessionStorage.setItem("property_info",JSON.stringify({...my_res,...body}));
        let route = "/propertyaddamenities";
        dispatch(postPropertyDetails(body, navigate, route));
      }
      
    }

   async function handleChange(name, value) {
      if (name === "expected_price") {
        
        let replacedVal = value.replaceAll(",","");
        let a = await numberWithCommas(replacedVal);
        
        setInputs({ ...inputs, [name]: a });
      } else {
        setInputs({ ...inputs, [name]: value });
      }
    }

    function handleChecks(e, name) {
      if (e.target.checked) {
        let my_prices = inputs.pricing_details;
        my_prices.push(name);
        setInputs({ ...inputs, pricing_details: my_prices });
      } else {
        let my_prices = inputs.pricing_details;
        let filtered = my_prices.filter((el) => el !== name);
        setInputs({ ...inputs, pricing_details: filtered });
      }
    }

    function handleDraft() {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let my_selected_imgs = propertyData?.property_images?.previews;
      let my_selected_videos = propertyData?.property_images?.videos;
      let fresh_arr = [...my_selected_imgs,...my_selected_videos];

      function dataURLtoFile(dataURL, filename) {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }   

      const convertImages = fresh_arr?.map((image) => {
        let up_file = dataURLtoFile(image.image, "image.jpg");
        let my_file = new FormData();
        my_file.append("file", up_file);
        return dispatch(addPhoto(my_file, setUploadedImgs, uploadedImgs,image.type,image.is_cover_image));
      });

      Promise.all(convertImages)
        .then(() => {
          handleSaveDraft();
        })
        .catch((error) => {
          console.error("Error uploading images:", error);
        });
    }

    function handleSaveDraft(){
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let actual_imgs = sessionStorage.getItem("converted_imgs");

      const body ={
        ...my_res,
        ownership: inputs.kinf_of_property,
        price: {
            expected_price: inputs.expected_price,
            pricing_details: inputs.pricing_details
        },
        property_details: inputs.about_property,
        approved_authority:inputs.approved_authority,
        pre_released_pre_rented:inputs.pre_leased
      };
      let price_convert = body["price"].expected_price?.replaceAll(",","");
      body["price"].expected_price = parseInt(price_convert);
      if (body["expected_time_of_possession"] === "-1") {
        body["expected_time_of_possession"] = "";
      }
      let newly_converted = JSON.parse(actual_imgs);
      let old_converted = propertyData?.property_images.existingImgs;
      if (newly_converted) {
        body["photos"] = [...old_converted, ...newly_converted];
      } else {
        body["photos"] = [...old_converted];
      }
      body["property_status"] = "Drafted";

      if (body["user_type"] === "661cc2f5267fb2f57d21fb8d") {
        body["owner_profile"] =  _docs?.photo ?  _docs?.photo :body["owner_profile"] ;
        body["owner_document"] =  _docs?.doc ?  _docs?.doc :body["owner_document"] ;
      }

      delete body.property_images;

      let split_photos = body["photos"];

      let splited_photos = [];
      let splited_videos = [];
      split_photos.forEach((val)=>{
        if (val.mime_type?.includes("image")) {
          splited_photos.push(val);
        } else {
          let video_val = val;
          video_val["video_name"] = val.image;
          delete val.image;
          splited_videos.push(video_val);
        }
      });
      body["photos"] = splited_photos;
      body["video"] = splited_videos;


      
      if (_edit) {
        delete body._id;
        delete body.__v;
        dispatch(updateProperty(body, _token, navigate, my_res?._id));
      } else {
        dispatch(createDraftProperty(body, _token, navigate));
      }
    };
    
    useEffect(()=>{
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      
      if (my_res) {
      setInputs({...inputs,
        expected_price: numberWithCommas(my_res?.price?.expected_price) ?? "",
        pricing_details: my_res?.price?.pricing_details ?? [],
        kinf_of_property: my_res?.ownership ?? "",
        about_property: my_res?.property_details ?? "",
        approved_authority: my_res?.approved_authority ?? "",
        pre_leased: my_res?.pre_released_pre_rented ?? ""
      })
    }
    },[])
    
 
    return (
      <>
        <Box className={classes.photos_box}>
          <Typography className={classes.role_box_header} mb={"25px"}>
            <span onClick={() => navigate("/propertyuploadphotos")}>
              <BackIcon />
            </span>{" "}
            {_edit
              ? ln && ln["edit"]
                ? ln["edit"]
                : "Edit"
              : ln && ln["add"]
              ? ln["add"]
              : "Add"}
              {" "}
              {ln && ln["pricing_and_details"]
              ? ln["pricing_and_details"]
              : "Pricing and Details"}
          </Typography>
          <Box>
            <ValidatorForm
              useref="form"
              onSubmit={handleNext}
              className={classes.SignUpForm}
            >
              <Box mb={2}>
                <Typography className={classes.my_role}>
                   {ln && ln["price_details"]
              ? ln["price_details"]
              : "Price details"}
                </Typography>

                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Enter expected price"
                  className={`${classes.formInputFeild} my-field`}
                  name="expected_price"
                  value={inputs.expected_price}
                  size="normal"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <TextField select>
                  //         <MenuItem>+91</MenuItem>
                  //         <MenuItem>+91</MenuItem>
                  //       </TextField>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  validators={["required"]} // Add this line
                  errorMessages={["Expected price is required"]}
                />
              </Box>
              <FormGroup>
                {userValues?.pricing_details.map((datas)=>{
                  return <FormControlLabel
                  sx={{ height: "40px" }}
                  control={
                    <Checkbox
                      checked={inputs.pricing_details.includes(datas._id)}
                      className={classes.checkbox_two}
                      onChange={(e) => handleChecks(e, datas._id)}
                    />
                  }
                  label={datas.text}
                />
                })}
              </FormGroup>
              <Box mb={3} mt={2}>
                <Typography className={classes.my_role}>
                {ln && ln["what_kind_of_property"] ? ln["what_kind_of_property"] : "What kind of property"}
                </Typography>
                <Box className={classes.wrapper}>
                  <Grid container spacing={2}>
                    {userValues?.ownership.map((datas) => (
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          className={
                            inputs.kinf_of_property === datas._id
                              ? classes.active_tab
                              : classes.inactive_tab
                          }
                          onClick={() =>
                            handleChange("kinf_of_property", datas._id)
                          }
                        >
                           {ln && ln[datas.text.toLowerCase()] ? ln[datas.text.toLowerCase()] : datas.text}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
              {(propertyData?.kind_of_property == "661cc347267fb2f57d21fb9a" || propertyData?.kind_of_property == "661cc398267fb2f57d21fba0") &&
              <>
              <Box mb={2}>
                <Typography className={classes.my_role}>
                 {ln && ln["approved_authority"] ? ln["approved_authority"] : "Approved authority"}
                </Typography>

                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Eg: BMRDA,BBMP etc"
                  className={`${classes.formInputFeild} my-field`}
                  name="approved_authority"
                  value={inputs.approved_authority}
                  size="normal"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <TextField select>
                  //         <MenuItem>+91</MenuItem>
                  //         <MenuItem>+91</MenuItem>
                  //       </TextField>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required"]} // Add this line
                  errorMessages={["Approved authority is required"]}
                />
              </Box>
              <Box mb={3}>
              <Typography className={classes.my_role}>
               {ln && ln["any_is_it_pre_leased_Pre_rented_"] ? ln["any_is_it_pre_leased_Pre_rented_"] : "Any Is it  pre-leased/Pre-rented ?"}
              </Typography>
              <Box className={classes.wrapper}>
                {preLeased.map((datas, i) => (
                  <Typography
                    className={
                      inputs.pre_leased === datas.value
                        ? classes.active_tab
                        : classes.inactive_tab
                    }
                    onClick={() => handleChange("pre_leased", datas.value)}
                    // minWidth={"44px"}
                    fullWidth
                  >
                     {ln && ln[datas.text.toLowerCase()] ? ln[datas.text.toLowerCase()] : datas.text}
                  </Typography>
                ))}
              </Box>
            </Box>
              </> }
              <Box mb={2}>
                <Typography className={classes.my_role}>
                  
                  {ln && ln["add_details_about_your_property"] ? ln["add_details_about_your_property"] : "Add details about your property"}
                </Typography>
                <TextValidator
                  variant="outlined"
                  multiline
                  rows={2}
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Share some details like facility, rooms etc.."
                  className={`${classes.formInputFeild} my-field`}
                  name="about_property"
                  value={inputs.about_property}
                  size="normal"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <TextField select>
                  //         <MenuItem>+91</MenuItem>
                  //         <MenuItem>+91</MenuItem>
                  //       </TextField>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required"]} // Add this line
                  errorMessages={["About Property is required"]}
                />
              </Box>

              <Box marginTop="40px" className="d-flex" gap="20px">
              { my_res?.property_status === "Drafted"  && 
                <Button fullWidth className={classes.draft_btn} onClick={handleDraft} >
                {ln && ln["save_as_draft"] ? ln["save_as_draft"] : "Save as draft"}
                </Button> }
                <Button fullWidth className={classes.continue} type="submit">
                {ln && ln["next"] ? ln["next"] : "Next"}
                </Button>
              </Box>
            </ValidatorForm>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<AddPricingDiv />} stepper={2} />
    </>
  );
}

export default PropertyPricingDetails;
