import { useEffect, useState } from "react";
import {
  BackIcon,
  GreenCalenderIcon,
  GreenLockIcon,
} from "../../Assets/Icons/icons";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
} from "@mui/material";
import { Numeric } from "../../Utilities/commonFunctions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import {
  createDraftProperty,
  createProperty,
  errorAlertAction,
  postPropertyDetails,
  updateProperty,
} from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "./property.css";
import GoogleMapPop from "../popup/GoogleMapPop";
import { useJsApiLoader } from "@react-google-maps/api";
import { MdEdit } from "react-icons/md";

let anyConstruction = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];
let _areas = ["sq.ft", "sq.yard", "sq.me", "cent", "gunta", "acre", "hectare"];

function PropertyAddDetails() {
  const AddPropertyDetailDiv = () => {
    const [isBuiltUp, setIsBuiltUp] = useState(false);
    const [mapPopup, setMapPopup] = useState(false);
    const [inputs, setInputs] = useState({
      location: "",
      city: "",
      area_of_property: "",
      no_of_bedrooms: "",
      no_of_bathrooms: "",
      total_floors: "",
      property_on_floor: "",
      availablity: "",
      age_of_property: "",
      built_up_area: "",
      super_built_up_area: "",
      expected_time_of_possession: "-1",
      no_of_washrooms: "",
      no_of_balcony: "",
      length: "",
      breadth: "",
      width_of_facing_road: "",
      no_of_open_sides: "",
      any_construction_done: "",
      facing: "",
      area_selected_unit: "sq.ft",
      built_up_area_unit: "sq.ft",
      super_built_up_area_unit: "sq.ft",
      furnishing_status: "",
      property_cordinates: { type: "Point", coordinates: [0,0] },
    });
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [centerPoint, setCenterPoint] = useState({
      lat: null,
      lng: null,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { propertyData, ln } = useSelector((state) => state.userLogin);
    const userValues = JSON.parse(sessionStorage.getItem("user_values"));
    let _edit = sessionStorage.getItem("edit");
    let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
    let _docs = JSON.parse(sessionStorage.getItem("converted_doc_imgs"));
    let my_res = JSON.parse(sessionStorage.getItem("property_info"));
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    });

    function handleNext() {
      if (
        propertyData?.kind_of_property === "661cde3d267fb2f57d2203e2" &&
        (inputs.location === "" ||
          inputs.area_of_property === "" ||
          inputs.no_of_bedrooms === "" ||
          inputs.no_of_bathrooms === "" ||
          inputs.total_floors === "" ||
          inputs.property_on_floor === "" ||
          inputs.availablity === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if (
        propertyData?.property_type === "661e4d8cc2322387fa060526" &&
        (inputs.location === "" ||
          inputs.area_of_property === "" ||
          inputs.total_floors === "" ||
          inputs.property_on_floor === "" ||
          inputs.availablity === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if (
        propertyData?.property_type === "661e4d97c2322387fa060529" &&
        (inputs.location === "" ||
          inputs.area_of_property === "" ||
          inputs.no_of_washrooms === "" ||
          inputs.no_of_balcony === "" ||
          inputs.total_floors === "" ||
          inputs.property_on_floor === "" ||
          inputs.availablity === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if (
        propertyData?.kind_of_property === "661cc347267fb2f57d21fb9a" &&
        (inputs.location === "" ||
          inputs.area_of_property === "" ||
          inputs.no_of_open_sides === "" ||
          inputs.any_construction_done === "" ||
          inputs.facing === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if (
        propertyData?.kind_of_property === "661cc398267fb2f57d21fba0" &&
        (inputs.location === "" ||
          inputs.area_of_property === "" ||
          inputs.no_of_open_sides === "" ||
          inputs.any_construction_done === "" ||
          inputs.facing === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select all fields"));
      } else {
        let body = {
          location: inputs.location,
          locality: inputs.location,
          city: inputs.city,
          super_built_up_area_unit: inputs.super_built_up_area_unit,
          built_up_area_unit: inputs.built_up_area_unit,
          area_selected_unit: inputs.area_selected_unit,
          property_cordinates: inputs.property_cordinates,
          built_up_area: inputs.built_up_area,
          super_built_up_area: inputs.super_built_up_area,
          area_of_property: inputs.area_of_property,
          room_details: {
            no_of_bedrooms: inputs.no_of_bedrooms,
            no_of_bathrooms: inputs.no_of_bathrooms,
            no_of_washrooms: inputs.no_of_washrooms,
            no_of_balcony: inputs.no_of_balcony,
            furnishing_status: inputs.furnishing_status,
          },

          floor_details: {
            total_floors: inputs.total_floors,
            property_on_floor: inputs.property_on_floor,
          },

          expected_time_of_possession: inputs.expected_time_of_possession,
          availability: inputs.availablity,
          age_of_property: inputs.age_of_property,
          property_dimensions: {
            length: inputs.length,
            breadth: inputs.breadth,
          },
          width_of_facing_road: inputs.width_of_facing_road,
          no_of_open_sides: inputs.no_of_open_sides,
          any_construction_done: inputs.any_construction_done,
          property_facing: inputs.facing,
        };

        let my_res = JSON.parse(sessionStorage.getItem("property_info"));

        sessionStorage.setItem(
          "property_info",
          JSON.stringify({ ...my_res, ...body })
        );
        let route = "/propertyuploadphotos";
        dispatch(postPropertyDetails(body, navigate, route));
      }
    }

    function handleChange(name, value) {
      setInputs({ ...inputs, [name]: value });
    }

    function handleSaveDraft() {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      let body = {
        ...my_res,
        location: inputs.location,
        locality: inputs.location,
        city: inputs.city,
        super_built_up_area_unit: inputs.super_built_up_area_unit,
        built_up_area_unit: inputs.built_up_area_unit,
        area_selected_unit: inputs.area_selected_unit,
        property_cordinates: inputs.property_cordinates,
        built_up_area: inputs.built_up_area,
        super_built_up_area: inputs.super_built_up_area,
        area_of_property: inputs.area_of_property,
        room_details: {
          no_of_bedrooms: inputs.no_of_bedrooms,
          no_of_bathrooms: inputs.no_of_bathrooms,
          no_of_washrooms: inputs.no_of_washrooms,
          no_of_balcony: inputs.no_of_balcony,
          furnishing_status: inputs.furnishing_status,
        },

        floor_details: {
          total_floors: inputs.total_floors,
          property_on_floor: inputs.property_on_floor,
        },

        expected_time_of_possession: inputs.expected_time_of_possession,
        availability: inputs.availablity,
        age_of_property: inputs.age_of_property,
        property_dimensions: {
          length: inputs.length,
          breadth: inputs.breadth,
        },
        width_of_facing_road: inputs.width_of_facing_road,
        no_of_open_sides: inputs.no_of_open_sides,
        any_construction_done: inputs.any_construction_done,
        property_facing: inputs.facing,
      };
      if (body["expected_time_of_possession"] === "-1") {
        body["expected_time_of_possession"] = "";
      }
      if (body["user_type"] === "661cc2f5267fb2f57d21fb8d") {
        body["owner_profile"] = _docs?.photo
          ? _docs?.photo
          : body["owner_profile"];
        body["owner_document"] = _docs?.doc
          ? _docs?.doc
          : body["owner_document"];
      }
      body["property_status"] = "Drafted";
      if (_edit) {
        delete body._id;
        delete body.__v;
        dispatch(updateProperty(body, _token, navigate, my_res?._id));
      } else {
        dispatch(createDraftProperty(body, _token, navigate));
      }
    }

    function handleClose() {
      setMapPopup(false);
      // setMarker(null);
      // setCenterPoint({
      //   lat: null,
      //   lng: null,
      // });
      // setInputs({ ...inputs, location: "", city: "" });
    }

    const getGeocode = async (lat, lng) => {
      try {
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            
            let lists = data?.results[0]?.address_components;

            let map_city = "";
            let map_location = "";
            lists?.forEach((val) => {
              if (val.types?.includes("locality")) {
                map_location = val.long_name;
              }
              if (val.types?.includes("administrative_area_level_3")) {
                map_city = val.long_name;
              }
            });
            let _coordinates = {
              type: "Point",
              coordinates: [lng,lat],
            };
            setInputs({
              ...inputs,
              location: map_location,
              city: map_city,
              property_cordinates: _coordinates,
            });
          })
          .catch((error) => {
            console.log(`Error getting location: ${error.message}`);
          });
      } catch (error) {
        console.error("Error fetching address:", error);
        console.log("Error fetching address");
      }
    };

    const handleClick = (event) => {
      
      setMarker({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      setCenterPoint({
        lat:event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      getGeocode(event.latLng.lat(), event.latLng.lng());
    };
    
    const handleAddMaps = () => {
      setMapPopup(false);
    };

    useEffect(() => {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      if (my_res) {
        let _coordinates = {
          type: "Point",
          coordinates: [
            my_res?.property_cordinates?.coordinates[0] ?? 0,
            my_res?.property_cordinates?.coordinates[1] ?? 0,
          ],
        };
        setInputs({
          ...inputs,
          location: my_res?.location ?? "",
          area_selected_unit: my_res?.area_selected_unit ?? "sq.ft",
          built_up_area_unit: my_res?.built_up_area_unit ?? "sq.ft",
          super_built_up_area_unit: my_res?.super_built_up_area_unit ?? "sq.ft",
          furnishing_status: my_res?.room_details
            ? my_res?.room_details?.furnishing_status
            : "",
          city: my_res?.city ?? "",
          built_up_area: my_res?.built_up_area ?? "",
          super_built_up_area: my_res?.super_built_up_area ?? "",
          area_of_property: my_res?.area_of_property ?? "",
          no_of_bedrooms: my_res?.room_details
            ? my_res?.room_details?.no_of_bedrooms
            : "",
          no_of_bathrooms: my_res?.room_details
            ? my_res?.room_details?.no_of_bathrooms
            : "",
          total_floors: my_res?.floor_details
            ? my_res?.floor_details?.total_floors
            : "",
          property_on_floor: my_res?.floor_details
            ? my_res?.floor_details?.property_on_floor
            : "",
          availablity: my_res?.availability ?? "",
          age_of_property: my_res?.age_of_property ?? "",
          expected_time_of_possession:
            my_res?.expected_time_of_possession ?? "-1",
          no_of_washrooms: my_res?.room_details
            ? my_res?.room_details?.no_of_washrooms
            : "",
          no_of_balcony: my_res?.room_details
            ? my_res?.room_details?.no_of_balcony
            : "",
          length: my_res?.property_dimensions?.length ?? "",
          breadth: my_res?.property_dimensions?.breadth ?? "",
          width_of_facing_road: my_res?.width_of_facing_road ?? "",
          no_of_open_sides: my_res?.no_of_open_sides ?? "",
          any_construction_done: my_res?.any_construction_done ?? "",
          facing: my_res?.property_facing ?? "",
          property_cordinates: _coordinates,
        });
      }

      if (
        my_res?.built_up_area?.toString()?.length > 0 ||
        my_res?.super_built_up_area?.toString()?.length > 0
      ) {
        setIsBuiltUp(true);
      }
    }, []);

    useEffect(() => {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      if (my_res?.property_cordinates?.coordinates) {
        setMarker({
          lat: my_res?.property_cordinates?.coordinates[1],
          lng: my_res?.property_cordinates?.coordinates[0],
        });
        setCenterPoint({
          lat: my_res?.property_cordinates?.coordinates[1],
          lng: my_res?.property_cordinates?.coordinates[0],
        });
      } else {
        setMarker(null);
      }

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, []);

    return (
      <>
        <Box className={classes.register_box}>
          <Typography className={classes.role_box_header} mb={"25px"}>
            <span onClick={() => navigate("/propertybasicdetails")}>
              <BackIcon />
            </span>{" "}
            {_edit
              ? ln && ln["edit"]
                ? ln["edit"]
                : "Edit"
              : ln && ln["add"]
              ? ln["add"]
              : "Add"}{" "}
            {ln && ln["property_details"]
              ? ln["property_details"]
              : "Property Details"}
          </Typography>
          <ValidatorForm
            useref="form"
            onSubmit={handleNext}
            className={classes.SignUpForm}
          >
            <Box mb={3}>
              <Typography className={classes.my_role}>
                Select your property location
              </Typography>
              {marker ? (
                <Button fullWidth className={classes.location_select_view}  onClick={() => setMapPopup(true)}>
                  {marker?.lat},{marker?.lng}
                  <MdEdit />
                </Button>
              ) : (
                <Button
                  fullWidth
                  className={classes.location_select}
                  onClick={() => setMapPopup(true)}
                >
                  Click to select
                </Button>
              )}
            </Box>
            <Box mb={3}>
              <Typography className={classes.my_role}>
                {ln && ln["where_is_it_located"]
                  ? ln["where_is_it_located"] + " ?"
                  : "Where is it located ?"}
              </Typography>
              <TextValidator
                disabled
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Location"
                className={`${classes.formInputFeild} my-field`}
                name="location"
                value={inputs.location}
                size="normal"
                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <TextField select>
                //         <MenuItem>+91</MenuItem>
                //         <MenuItem>+91</MenuItem>
                //       </TextField>
                //     </InputAdornment>
                //   ),
                // }}
                // onKeyPress={(e) => {
                //   Numeric(e);
                // }}
                validators={["required"]} // Add this line
                errorMessages={["Location is required"]}
              />
            </Box>
            <Box mb={3}>
              <Typography className={classes.my_role}>
                {" "}
                {ln && ln["city"] ? ln["city"] : "City"}{" "}
              </Typography>
              <TextValidator
                disabled
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="City"
                className={`${classes.formInputFeild} my-field`}
                name="city"
                value={inputs.city}
                size="normal"
                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                validators={["required"]} // Add this line
                errorMessages={["City is required"]}
              />
            </Box>
            <Box mb={3}>
              <Typography className={classes.my_role}>
                {ln && ln["area_of_property"]
                  ? ln["area_of_property"]
                  : "Area of property"}
              </Typography>

              <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Area"
                className={`${classes.formInputFeild} my-field`}
                name="area_of_property"
                value={inputs.area_of_property}
                size="normal"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ margin: "0px" }}>
                      <TextValidator
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        select
                        id="emailInput"
                        placeholder="Expected by"
                        className={`${classes.formInputFeild} my-field select-field inner-select`}
                        name="expected_time_of_possession"
                        value={inputs.area_selected_unit}
                        size="normal"
                        validators={["required"]} // Add this line
                        errorMessages={[
                          "Expected time of possession is required",
                        ]}
                      >
                        <MenuItem value={"-1"} sx={{ display: "none" }}>
                          <p style={{ color: "rgba(0,0,0,0.3)" }}>
                            {ln && ln["expected_time_of_possession"]
                              ? ln["expected_time_of_possession"]
                              : "Sq.ft"}
                          </p>
                        </MenuItem>
                        {_areas.map((item, index) => {
                          return (
                            <MenuItem
                              value={item}
                              key={index}
                              onClick={(e) =>
                                handleChange("area_selected_unit", item)
                              }
                              style={{ textTransform: "capitalize" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </TextValidator>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                validators={["required"]} // Add this line
                errorMessages={["Area of property is required"]}
              />
              <Typography
                sx={{
                  color: "green",
                  fontSize: "14px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
                onClick={() => setIsBuiltUp(!isBuiltUp)}
              >
                {ln && ln["add_built_up_area"]
                  ? ln["add_built_up_area"]
                  : " Add Built-up Area & Super Built-up Area"}
              </Typography>
            </Box>
            {isBuiltUp && (
              <>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    Built-up Area
                  </Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Area"
                    className={`${classes.formInputFeild} my-field`}
                    name="built_up_area"
                    value={inputs.built_up_area}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ margin: "0px" }}
                        >
                          <TextValidator
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            select
                            id="emailInput"
                            placeholder="Expected by"
                            className={`${classes.formInputFeild} my-field select-field inner-select`}
                            name="expected_time_of_possession"
                            value={inputs.built_up_area_unit}
                            size="normal"
                            validators={["required"]} // Add this line
                            errorMessages={[
                              "Expected time of possession is required",
                            ]}
                          >
                            <MenuItem value={"-1"} sx={{ display: "none" }}>
                              <p style={{ color: "rgba(0,0,0,0.3)" }}>
                                {ln && ln["expected_time_of_possession"]
                                  ? ln["expected_time_of_possession"]
                                  : "Sq.ft"}
                              </p>
                            </MenuItem>
                            {_areas.map((item, index) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={index}
                                  onClick={(e) =>
                                    handleChange("built_up_area_unit", item)
                                  }
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextValidator>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    Super Built-up Area
                  </Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Area"
                    className={`${classes.formInputFeild} my-field`}
                    name="super_built_up_area"
                    value={inputs.super_built_up_area}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ margin: "0px" }}
                        >
                          <TextValidator
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            select
                            id="emailInput"
                            placeholder="Expected by"
                            className={`${classes.formInputFeild} my-field select-field inner-select`}
                            name="expected_time_of_possession"
                            value={inputs.super_built_up_area_unit}
                            size="normal"
                            validators={["required"]} // Add this line
                            errorMessages={[
                              "Expected time of possession is required",
                            ]}
                          >
                            <MenuItem value={"-1"} sx={{ display: "none" }}>
                              <p style={{ color: "rgba(0,0,0,0.3)" }}>
                                {ln && ln["expected_time_of_possession"]
                                  ? ln["expected_time_of_possession"]
                                  : "Sq.ft"}
                              </p>
                            </MenuItem>
                            {_areas.map((item, index) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={index}
                                  onClick={(e) =>
                                    handleChange(
                                      "super_built_up_area_unit",
                                      item
                                    )
                                  }
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextValidator>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </>
            )}
            {propertyData?.kind_of_property !== "661cc37f267fb2f57d21fb9d" &&
              propertyData?.property_type !== "661e4d97c2322387fa060529" &&
              propertyData?.kind_of_property !== "661cc347267fb2f57d21fb9a" &&
              propertyData?.kind_of_property !== "661cc398267fb2f57d21fba0" && (
                <>
                  <Box mb={3}>
                    <Typography className={classes.my_role}>
                      {ln && ln["no_of_bedrooms"]
                        ? ln["no_of_bedrooms"]
                        : "No. of bedrooms"}
                    </Typography>
                    <Box className={classes.wrapper}>
                      {userValues?.no_of_bedrooms.map((datas, i) => (
                        <Typography
                          className={
                            inputs.no_of_bedrooms == datas._id
                              ? classes.active_tab
                              : classes.inactive_tab
                          }
                          onClick={() =>
                            handleChange("no_of_bedrooms", datas._id)
                          }
                          minWidth={"44px"}
                        >
                          {ln && ln[datas.text.toLowerCase()]
                            ? ln[datas.text.toLowerCase()]
                            : datas.text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box mb={3}>
                    <Typography className={classes.my_role}>
                      {ln && ln["no_of_bathrooms"]
                        ? ln["no_of_bathrooms"]
                        : "No. of bathrooms"}
                    </Typography>
                    <Box className={classes.wrapper}>
                      {userValues?.no_of_bathrooms.map((datas) => (
                        <Typography
                          className={
                            inputs.no_of_bathrooms == datas._id
                              ? classes.active_tab
                              : classes.inactive_tab
                          }
                          onClick={() =>
                            handleChange("no_of_bathrooms", datas._id)
                          }
                          minWidth={"44px"}
                        >
                          {ln && ln[datas.text.toLowerCase()]
                            ? ln[datas.text.toLowerCase()]
                            : datas.text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            {propertyData?.kind_of_property === "661cde3d267fb2f57d2203e2" && (
              <Box mb={3} mt={2}>
                <Typography className={classes.my_role}>
                  Furnishing Status
                </Typography>
                <Box className={classes.wrapper}>
                  <Grid container spacing={2}>
                    {userValues?.furnishing_status.map((datas) => (
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          className={
                            inputs.furnishing_status === datas._id
                              ? classes.active_tab
                              : classes.inactive_tab
                          }
                          onClick={() =>
                            handleChange("furnishing_status", datas._id)
                          }
                          minWidth={"80px"}
                        >
                          {ln && ln[datas.text.toLowerCase()]
                            ? ln[datas.text.toLowerCase()]
                            : datas.text}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}
            {propertyData?.kind_of_property !== "661cc347267fb2f57d21fb9a" &&
              propertyData?.kind_of_property !== "661cc398267fb2f57d21fba0" && (
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["floor_details"]
                      ? ln["floor_details"]
                      : "Floor details"}
                  </Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Total floor"
                    className={`${classes.formInputFeild} my-field`}
                    name="total_floors"
                    value={inputs.total_floors}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    style={{ margin: "0px 0 14px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    validators={["required"]} // Add this line
                    errorMessages={["Total floors is required"]}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Property on floor"
                    className={`${classes.formInputFeild} my-field`}
                    name="property_on_floor"
                    value={inputs.property_on_floor}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    validators={["required"]} // Add this line
                    errorMessages={["Property on floor is required"]}
                  />
                </Box>
              )}
            {(propertyData?.kind_of_property == "661cc347267fb2f57d21fb9a" ||
              propertyData?.kind_of_property == "661cc398267fb2f57d21fba0") && (
              <>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["property_dimensions"]
                      ? ln["property_dimensions"]
                      : "Property dimensions"}
                  </Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Length in ft"
                    className={`${classes.formInputFeild} my-field`}
                    name="length"
                    value={inputs.length}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    style={{ margin: "0px 0 14px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    // validators={["required"]} 
                    // errorMessages={["Length is required"]}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Breadth in ft"
                    className={`${classes.formInputFeild} my-field`}
                    name="breadth"
                    value={inputs.breadth}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    // validators={["required"]} 
                    // errorMessages={["Breadth is required"]}
                  />
                </Box>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["width_of_facing_road"]
                      ? ln["width_of_facing_road"]
                      : "Width of facing road"}
                  </Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter width of the road"
                    className={`${classes.formInputFeild} my-field`}
                    name="width_of_facing_road"
                    value={inputs.width_of_facing_road}
                    size="normal"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    style={{ margin: "0px 0 14px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    // validators={["required"]} 
                    // errorMessages={["Width is required"]}
                  />
                </Box>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["number_of_open_sides"]
                      ? ln["number_of_open_sides"]
                      : "Number of open sides"}
                  </Typography>
                  <Box className={classes.wrapper}>
                    {userValues?.no_of_open_sides.map((datas, i) => (
                      <Typography
                        className={
                          inputs.no_of_open_sides === datas._id
                            ? classes.active_tab
                            : classes.inactive_tab
                        }
                        onClick={() =>
                          handleChange("no_of_open_sides", datas._id)
                        }
                        minWidth={"44px"}
                      >
                        {ln && ln[datas.text.toLowerCase()]
                          ? ln[datas.text.toLowerCase()]
                          : datas.text}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["any_construction"]
                      ? ln["any_construction"]
                      : "Any construction"}
                  </Typography>
                  <Box className={classes.wrapper}>
                    {anyConstruction.map((datas, i) => (
                      <Typography
                        className={
                          inputs.any_construction_done === datas.value
                            ? classes.active_tab
                            : classes.inactive_tab
                        }
                        onClick={() =>
                          handleChange("any_construction_done", datas.value)
                        }
                        // minWidth={"44px"}
                        fullWidth
                      >
                        {ln && ln[datas.text.toLowerCase()]
                          ? ln[datas.text.toLowerCase()]
                          : datas.text}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box mb={3} mt={2}>
                  <Typography className={classes.my_role}>
                    {ln && ln["property_facing"]
                      ? ln["property_facing"]
                      : "Property facing"}
                  </Typography>
                  <Box className={classes.wrapper}>
                    <Grid container spacing={2}>
                      {userValues?.property_facing.map((datas) => (
                        <Grid item xs={6} sm={6} md={3}>
                          <Typography
                            className={
                              inputs.facing === datas._id
                                ? classes.active_tab
                                : classes.inactive_tab
                            }
                            onClick={() => handleChange("facing", datas._id)}
                            minWidth={"80px"}
                          >
                            {ln && ln[datas.text.toLowerCase()]
                              ? ln[datas.text.toLowerCase()]
                              : datas.text}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </>
            )}
            {propertyData?.property_type === "661e4d97c2322387fa060529" && (
              <>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["no_of_washrooms"]
                      ? ln["no_of_washrooms"]
                      : "No. of Washrooms"}
                  </Typography>
                  <Box className={classes.wrapper}>
                    {userValues?.no_of_washrooms.map((datas, i) => (
                      <Typography
                        className={
                          inputs.no_of_washrooms === datas._id
                            ? classes.active_tab
                            : classes.inactive_tab
                        }
                        onClick={() =>
                          handleChange("no_of_washrooms", datas._id)
                        }
                        minWidth={"44px"}
                      >
                        {ln && ln[datas.text.toLowerCase()]
                          ? ln[datas.text.toLowerCase()]
                          : datas.text}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["no_of_balconies"]
                      ? ln["no_of_balconies"]
                      : "No. of balcony"}
                  </Typography>
                  <Box className={classes.wrapper}>
                    {userValues?.no_of_balconies.map((datas) => (
                      <Typography
                        className={
                          inputs.no_of_balcony === datas._id
                            ? classes.active_tab
                            : classes.inactive_tab
                        }
                        onClick={() => handleChange("no_of_balcony", datas._id)}
                        minWidth={"44px"}
                      >
                        {ln && ln[datas.text.toLowerCase()]
                          ? ln[datas.text.toLowerCase()]
                          : datas.text}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </>
            )}
            {propertyData?.kind_of_property !== "661cc398267fb2f57d21fba0" &&
              propertyData?.kind_of_property !== "661cc347267fb2f57d21fb9a" && (
                <Box mb={3}>
                  <Typography className={classes.my_role}>
                    {ln && ln["availablity"]
                      ? ln["availablity"]
                      : "Availablity"}
                  </Typography>
                  <Box className={classes.wrapper}>
                    {userValues?.availability.map((datas) => (
                      <Typography
                        className={
                          inputs.availablity === datas._id
                            ? classes.active_tab
                            : classes.inactive_tab
                        }
                        onClick={() => handleChange("availablity", datas._id)}
                      >
                        {ln && ln[datas.text.toLowerCase()]
                          ? ln[datas.text.toLowerCase()]
                          : datas.text}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )}
            {inputs.availablity == "661cc603267fb2f57d21fc0c" && (
              <Box>
                <Typography className={classes.my_role}>
                  {ln && ln["age_of_property"]
                    ? ln["age_of_property"]
                    : "Age of the property"}
                </Typography>
                <Box className={classes.wrapper} gap={"6px"}>
                  {userValues?.age_of_property.map((datas) => (
                    <Typography
                      className={
                        inputs.age_of_property === datas._id
                          ? classes.active_tab
                          : classes.inactive_tab
                      }
                      onClick={() => handleChange("age_of_property", datas._id)}
                    >
                      {ln && ln[datas.text.toLowerCase()]
                        ? ln[datas.text.toLowerCase()]
                        : datas.text}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
            {inputs.availablity == "661cc5f8267fb2f57d21fc09" && (
              <Box mb={3}>
                <Typography className={classes.my_role}>
                  {ln && ln["expected_time_of_possession"]
                    ? ln["expected_time_of_possession"]
                    : "Expected time of possession"}
                </Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  select
                  id="emailInput"
                  placeholder="Expected by"
                  className={`${classes.formInputFeild} my-field select-field`}
                  name="expected_time_of_possession"
                  value={inputs.expected_time_of_possession}
                  size="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: "8px" }}
                      >
                        <GreenCalenderIcon />
                      </InputAdornment>
                    ),
                  }}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required"]} // Add this line
                  errorMessages={["Expected time of possession is required"]}
                >
                  <MenuItem value={"-1"} sx={{ display: "none" }}>
                    <p style={{ color: "rgba(0,0,0,0.3)" }}>
                      {ln && ln["expected_time_of_possession"]
                        ? ln["expected_time_of_possession"]
                        : "Expected time of possession"}
                    </p>
                  </MenuItem>
                  {userValues?.expected_time_of_possession.map(
                    (item, index) => {
                      return (
                        <MenuItem
                          value={item._id}
                          onClick={(e) =>
                            handleChange(
                              "expected_time_of_possession",
                              item._id
                            )
                          }
                        >
                          <p className={`${classes.selectAge}`}>{item.text}</p>
                        </MenuItem>
                      );
                    }
                  )}
                </TextValidator>
              </Box>
            )}
            <Box marginTop="40px" className="d-flex" gap="20px">
              {my_res?.property_status === "Drafted" && (
                <Button
                  fullWidth
                  className={classes.draft_btn}
                  onClick={handleSaveDraft}
                >
                  {ln && ln["save_as_draft"]
                    ? ln["save_as_draft"]
                    : "Save as draft"}
                </Button>
              )}
              <Button fullWidth className={classes.continue} type="submit">
                {ln && ln["next"] ? ln["next"] : "Next"}
              </Button>
            </Box>
          </ValidatorForm>
        </Box>
        <GoogleMapPop
          open={mapPopup}
          handleClose={handleClose}
          isLoaded={isLoaded}
          setMap={setMap}
          marker={marker}
          centerPoint={centerPoint}
          setCenterPoint={setCenterPoint}
          handleClick={handleClick}
          handleAddMaps={handleAddMaps}
        />
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<AddPropertyDetailDiv />} stepper={1} />
    </>
  );
}

export default PropertyAddDetails;
