import { api, authApi, getAuthorizationHeader } from "../../Api/api";
import { ALL_PROPERTIES, EMPTY_PROPERTY_DATA,CLEAR_PROPERTY_DATA, ERROR_ALERT, GET_LANGUAGE_DATA_SUCCESS, HEADER_NAME, OTP_VERIFICATION_REQUEST_FAIL, OTP_VERIFICATION_REQUEST_SUCCESS, PAGE_LOADER, PROPERTY_DATA, RESEND_OTP_VALUE, SUCCESS_ALERT, TOGGLE_SIDEBAR, USER_LOGIN_REQUEST_FAIL, USER_LOGIN_REQUEST_SUCCESS, USER_REGISTER_REQUEST_FAIL, USER_REGISTER_REQUEST_SUCCESS, FILTER_DETAILS, SERVICES_LIST } from "../Constants/userConstants";

export const loginUser =
    (userEntry, navigate, setErrorAlert) => async (dispatch) => {
        try {
            const { data } = await api.post("/auth/login/user", userEntry);
            
            if (data.success) {
                sessionStorage.setItem("otp_data", JSON.stringify(data.data[0]))
                dispatch(pageLoader(false));
                navigate("/login-otp-verification");
                dispatch({
                    type: RESEND_OTP_VALUE,
                    payload: data.data[0]
                })
            }
        } catch (error) {
            dispatch({
                type: USER_LOGIN_REQUEST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            setErrorAlert(true);
            dispatch(pageLoader(false));
        }
    };

export const setHeaderName = (name) => async (dispatch) => {
    dispatch({
        type: HEADER_NAME,
        payload: name,
    });
};

export const pageLoader = (value) => async (dispatch) => {
    dispatch({
        type: PAGE_LOADER,
        payload: value,
    });
};

export const setToggleSidebar = (value) => async (dispatch) => {
    dispatch({
        type: TOGGLE_SIDEBAR,
        payload: value,
    });
};

export const userRegister = (setErrorAlert, userEntry, navigate) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/register', userEntry)
        if (data.success) {
            
            sessionStorage.setItem("otp_data", JSON.stringify(data.data[0]));
            dispatch({
                type: RESEND_OTP_VALUE,
                payload: data.data[0]
            })
            navigate('/register-otp-verification')
        }
    }
    catch (error) {
        dispatch({
            type: USER_REGISTER_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        setErrorAlert(true)
        dispatch(pageLoader(false));
    }
}

export const verifyOtp = (setErrorAlert, userEntry, navigate, route) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/verify_otp', userEntry)
        if (data.success) {
            localStorage.setItem("userToken", JSON.stringify(data.data[0]));
            dispatch({
                type: OTP_VERIFICATION_REQUEST_SUCCESS,
                payload: data.data[0]
            })
            if (route === "/personal-details") {
                navigate(route)
            } else {
                const userToken = JSON.parse(localStorage.getItem("userToken"));
                let registerInfos = JSON.parse(sessionStorage.getItem("registerInfos"));
                const userEntry = {
                    name: registerInfos?.name,
                    email: registerInfos?.email,
                    location: registerInfos?.location,
                  };
                  // dispatch(pageLoader(true));
                  
                  dispatch(
                    enterPersonalInfo(setErrorAlert, userEntry, userToken?.id, navigate)
                  );
            }
            
        }
    }
    catch (error) {
        dispatch({
            type: OTP_VERIFICATION_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        setErrorAlert(true)
        dispatch(pageLoader(false));
    }
}

export const verifyLoginOtp = (setErrorAlert, userEntry, navigate) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/verify_login_otp', userEntry)
        if (data.success) {
            localStorage.setItem("userToken", JSON.stringify(data.data[0]));
            dispatch({
                type: OTP_VERIFICATION_REQUEST_SUCCESS,
                payload: data.data[0]
            })
            navigate("/homepage")
        }
    }
    catch (error) {
        dispatch({
            type: OTP_VERIFICATION_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        setErrorAlert(true)
        dispatch(pageLoader(false));
    }
}

export const resendOtp = (setErrorAlert, userEntry, setTime) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/resend_otp', userEntry)
        if (data.success) {
            dispatch(pageLoader(false))
            sessionStorage.setItem("otp_data", JSON.stringify(data.data[0]));
            dispatch({
                type: RESEND_OTP_VALUE,
                payload: data.data[0]
            })
            setTime(60)
        }
    }
    catch (error) {
        dispatch({
            type: OTP_VERIFICATION_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        setErrorAlert(true)
        dispatch(pageLoader(false));
    }
}

export const enterPersonalInfo = (setErrorAlert, userEntry, id, navigate) => async (dispatch) => {
    try {
        const token = JSON.parse(localStorage.getItem("userToken"));

        const { data } = await authApi.put(`/user/${id}`, userEntry, {
            headers: {
                "Authorization": `Bearer ${token.token}`
            }
        })
        if (data.success) {
            let existing_userdata = JSON.parse(localStorage.getItem("userToken"));
            existing_userdata["name"] = data?.data[0]?.name;
            existing_userdata["phone"] = data?.data[0]?.phone;
            existing_userdata["email"] = data?.data[0]?.email;

            localStorage.setItem("userToken", JSON.stringify(existing_userdata));
            dispatch({
                type: USER_REGISTER_REQUEST_SUCCESS,
                payload: data.message
            })
            navigate('/homepage')
        }
    }
    catch (error) {
        dispatch({
            type: USER_REGISTER_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        setErrorAlert(true)
        dispatch(pageLoader(false));
    }
}

export const updateProfile = (id,userEntry,setUpdateSuccess,setUpdateFailure) => async (dispatch) => {
    try {
        const token = JSON.parse(localStorage.getItem("userToken"));

        const { data } = await authApi.put(`/user/${id}`, userEntry, {
            headers: {
                "Authorization": `Bearer ${token.token}`
            }
        })
        if (data.success) {
            let existing_userdata = JSON.parse(localStorage.getItem("userToken"));
            existing_userdata["name"] = data?.data[0]?.name;
            existing_userdata["phone"] = data?.data[0]?.phone;
            existing_userdata["email"] = data?.data[0]?.email;
            existing_userdata["country_code"] = userEntry?.country_code;

            localStorage.setItem("userToken", JSON.stringify(existing_userdata));
            setUpdateSuccess(true);
        }
    }
    catch (error) {
        setUpdateFailure(true);
        dispatch(pageLoader(false));
    }
}

export const getLanguageData = (code, setErrorAlert) => async (dispatch) => {
    try {
        const { data } = await api.get(`/language/${code}`)
        dispatch({
            type: GET_LANGUAGE_DATA_SUCCESS,
            payload: data
        })
        dispatch(pageLoader(false));

    }
    catch (error) {
        dispatch({
            type: USER_REGISTER_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        setErrorAlert(true)
        dispatch(pageLoader(false));
    }
};

export const postPropertyDetails = (value,navigate,route) => async (dispatch) => {
    dispatch({
        type: PROPERTY_DATA,
        payload: value,
    });
    navigate(route)
};

export const errorAlertAction = (value) => async (dispatch) => {

    dispatch({
        type: ERROR_ALERT,
        payload: value,
    });
};

export const successAlertAction = (value) => async (dispatch) => {

    dispatch({
        type: SUCCESS_ALERT,
        payload: value,
    });
};

export const clearPropertyData = (value) => async (dispatch) => {

    dispatch({
        type: CLEAR_PROPERTY_DATA,
        payload: value,
    });
};


export const createProperty = (body,_token,navigate) => async (dispatch) => {
    try {
        const { data } = await api.post(`/property/create-property`,body,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        });
        
        // dispatch({
        //     type: GET_LANGUAGE_DATA_SUCCESS,
        //     payload: data
        // })
        // dispatch(pageLoader(false));
        if (data?.data) {
            
           sessionStorage.removeItem("property_info");
           sessionStorage.removeItem("converted_imgs");
           dispatch({
            type:EMPTY_PROPERTY_DATA,
            payload:{}
           })
           navigate("/created");
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const createDraftProperty = (body,_token,navigate) => async (dispatch) => {
    try {
        const { data } = await api.post(`/property/create-property`,body,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        });
        
        // dispatch({
        //     type: GET_LANGUAGE_DATA_SUCCESS,
        //     payload: data
        // })
        // dispatch(pageLoader(false));
        if (data?.data) {
            
           sessionStorage.removeItem("property_info");
           sessionStorage.removeItem("converted_imgs");
           dispatch({
            type:EMPTY_PROPERTY_DATA,
            payload:{}
           });
           localStorage.setItem("success","1");
           dispatch(successAlertAction("Property Saved as draft"));
           setTimeout(()=>{
            navigate("/alllistings");
           },3000);
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const updateProperty = (body,_token,navigate,_id) => async (dispatch) => {
    try {
        const { data } = await api.put(`/property/update-property/${_id}`,body,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        });

        if (data?.data) {
            localStorage.setItem("success","1");
            dispatch(successAlertAction("Property Updated successfully"));

            setTimeout(() => {
                sessionStorage.clear();
                dispatch({
                 type:EMPTY_PROPERTY_DATA,
                 payload:{}
                });
                navigate("/alllistings");
            }, 3000);

        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const deleteProperty = (_token,_id,setDeletePop,setDeleteFlag) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/property/delete-property/${_id}`,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        });

        if (data?.data) {
            setDeleteFlag(true);
            setDeletePop(false);
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const updatePropertyStatus = (_token,_id,setDeletePop,setDeleteFlag,body) => async (dispatch) => {
    try {
        const { data } = await api.put(`/property/update-property/${_id}`,body,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        });

        if (data?.data) {
            setDeleteFlag(true);
            setDeletePop(false);
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const singleProperty = (_token,_id,setPropertyDetails,navigate,page) => async (dispatch) => {
    try {
        const { data } = await api.get(`/property/property/${_id}`,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        });

        if (data?.data) {
            if (page === "edit") {
                sessionStorage.setItem(
                    "property_info",
                    JSON.stringify(data?.data[0]?.originalData)
                  );
                  sessionStorage.setItem("edit", "yes");
                  navigate("/postproperty");
            } else {
                let single_details = data?.data[0];
                let photos = single_details?.modifiedData?.photos;
                let videos = single_details?.modifiedData?.video;
                let img_merged = [...photos,...videos];
                single_details["modifiedData"].allphotos = img_merged;
                setPropertyDetails(single_details)
            }

            
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const addPhoto = (body,setUploadedImgs,uploadedImgs,img_type,coverImage) => async (dispatch) => {
    try {
        let headers = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "type": "formData"
          };
        const { data } = await api.post(`/property/upload-file`,body,{headers});
        
        // dispatch({
        //     type: GET_LANGUAGE_DATA_SUCCESS,
        //     payload: data
        // })
        // dispatch(pageLoader(false));
        if (data?.data) {
            let obj = {
                image: `uploads/${data?.data?.filename}`,
                type: img_type,
                original_name: data?.data?.originalname,
                mime_type: data?.data?.mimetype,
                is_cover_image:coverImage
              };
            let my_existing_photo = sessionStorage.getItem("converted_imgs");
            let a = JSON.parse(my_existing_photo);
            if (a) {
                let existImgs = [...a];
                existImgs.push(obj);
                sessionStorage.setItem("converted_imgs",JSON.stringify(existImgs))
            } else {
                let new_arr = [obj];
                sessionStorage.setItem("converted_imgs", JSON.stringify(new_arr))
            }

        }
    }
    catch (error) {
        
        dispatch({
            type: USER_REGISTER_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const addDocumentPhoto = (body,field_name,dynamicField,img_type) => async (dispatch) => {
    try {
        let headers = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "type": "formData"
          };
        const { data } = await api.post(`/property/upload-file`,body,{headers});
        
        if (data?.data) {
            let obj = {
                [dynamicField]: `uploads/${data?.data?.filename}`,
                type: img_type,
                original_name: data?.data?.originalname,
                mime_type: data?.data?.mimetype,
              };

            let initialDoc = {
                [field_name] : obj,
              };
            let my_existing_photo = sessionStorage.getItem("converted_doc_imgs");
            let a = JSON.parse(my_existing_photo);
            if (a) {
                let existImgs = {...a};
                existImgs[field_name] = {...initialDoc[field_name]};
                sessionStorage.setItem("converted_doc_imgs",JSON.stringify(existImgs))
            } else {
                let new_arr = initialDoc;
                sessionStorage.setItem("converted_doc_imgs", JSON.stringify(new_arr))
            }
        }
    }
    catch (error) {
        
        dispatch({
            type: USER_REGISTER_REQUEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
};

export const registerUser = ( userEntry, navigate) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/register', userEntry)
        if (data.success) {
            navigate('/propertyregisterverify',{state:{otp_details:data?.data[0]}})
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const otpVerification = ( userEntry, navigate, route) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/verify_otp', userEntry)
        if (data.success) {
            localStorage.setItem("userToken", JSON.stringify(data.data[0]));
            navigate(route)
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
        dispatch(pageLoader(false));
    }
}

export const resendUserOtp = (userEntry, setSeconds,setNewOtp) => async (dispatch) => {
    try {
        const { data } = await api.post('/auth/resend_otp', userEntry)
        if (data.success) {
            dispatch(pageLoader(false));
            setSeconds(20);
            setNewOtp(data?.data[0]?.otp)
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        dispatch(pageLoader(false));
    }
}

export const allUserProperties = ( _token,setMyProperties,user_id,sorting,propertyFilter) => async (dispatch) => {
    try {
        let filters = propertyFilter;
        if (propertyFilter === "-1") {
            filters = ""
        }
        const { data } = await api.get(`/property/user-property/${user_id}?page=${sorting.page}&limit=10&query&sort=createdAt-${sorting.listing}&status=${filters}`,{
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data?.success) {
            setMyProperties(data?.data);
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const getAllValues = (setUserValues) => async (dispatch) => {
    try {
        const { data } = await api.get(`/frontend-values/`
        // ,{
        //     headers: {
        //         "Authorization": `Bearer ${_token}`
        //     }
        // }
    )
        if (data?.success) {
            setUserValues(data?.data);
            sessionStorage.setItem("user_values",JSON.stringify(data?.data));
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const getAllProperties = ( _token) => async (dispatch) => {
    try {
        const { data } = await api.get(`/property/all-property/?page=1&limit=10&query=&status=&postedBy=&sort=createdAt-dsc&location=&area=&property_type=&category=&owner_type=`,{
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data?.success) {
            dispatch({
                type: ALL_PROPERTIES,
                payload: data?.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const allUserWishlistProperties = ( _token,setMyProperties,setMyFullProperties,user_id) => async (dispatch) => {
    try {
        const { data } = await api.get(`/property/user-wishlist-property/${user_id}`,{
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data?.success) {
            setMyProperties(data?.data);
            setMyFullProperties(data?.data);
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const addWishlist = ( heart,setHeart,property_id,handleSingleProperty) => async (dispatch) => {
    
    try {
        const { data } = await authApi.put(`/property/property-wishlist/${property_id}`,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data?.success) {
            
            localStorage.setItem("success","1");
            dispatch(successAlertAction(data?.data[0]?.message));
            handleSingleProperty();
            setHeart(!heart);
        }
    }
    catch (error) {
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const getAllHomeProperties = ( _token,myFilters,my_params) => async (dispatch) => {
    try {
        // const { data } = await api.get(`/property/all-property/?page=1&limit=108&query=&status=&postedBy=${myFilters.user_type?.toString()}&sort=createdAt-${myFilters.listing}&location=${myFilters.localities?.toString()}&area=&property_type=${myFilters.property_type?.toString()}&kind_of_property=${myFilters.kind_of_property?.toString()}&category=&owner_type=&amenities=${myFilters.amenities?.toString()}&price=expected_price-${myFilters.price}&availability=${myFilters.availability?.toString()}&minPrice=${myFilters.rangeValue[0]}&maxPrice=${myFilters.rangeValue[1]}&photos=${myFilters.photos}&videos=${myFilters.videos}&furnishing_status=${myFilters.furnishing_status?.toString()}`,{
        //     headers: {
        //         "Authorization": `Bearer ${_token}`
        //     }
        // });
      
        if (my_params === "allproperties") {
            const { data } = await api.get(`/property/all-property/?page=${myFilters?.page}&limit=10&query=&status=&postedBy=${myFilters.user_type?.toString()}&room_type=${myFilters?.room_type?.toString()}&sort=${myFilters.listing}&location=${myFilters.localities?.toString()}&area=&property_type=${myFilters.property_type?.toString()}&kind_of_property=${myFilters.kind_of_property?.toString()}&rera_approved=${myFilters.isRera}&is_verified=${myFilters.isVerified}&amenities=${myFilters.amenities?.toString()}&availability=${myFilters.availability?.toString()}&minPrice=${myFilters.rangeValue[0]}&maxPrice=${myFilters.rangeValue[1]}&minArea=${myFilters.areaRangeValue[0]}&maxArea=${myFilters.areaRangeValue[1]}&photos=${myFilters.photos}&videos=${myFilters.videos}&furnishing_status=${myFilters.furnishing_status?.toString()}`,{
                headers: {
                    "Authorization": `Bearer ${_token}`
                }
            });
            if (data?.success) {
                dispatch({
                    type: ALL_PROPERTIES,
                    payload: data?.data
                })
            }
        } else {
            let my_lat = my_params?.split(",");
            const { data } = await api.get(`/property/all-property/?page=${myFilters?.page}&limit=10&query=&status=&postedBy=${myFilters.user_type?.toString()}&room_type=${myFilters?.room_type?.toString()}&sort=${myFilters.listing}&location=${myFilters.localities?.toString()}&area=&property_type=${myFilters.property_type?.toString()}&kind_of_property=${myFilters.kind_of_property?.toString()}&rera_approved=${myFilters.isRera}&is_verified=${myFilters.isVerified}&amenities=${myFilters.amenities?.toString()}&availability=${myFilters.availability?.toString()}&minPrice=${myFilters.rangeValue[0]}&maxPrice=${myFilters.rangeValue[1]}&minArea=${myFilters.areaRangeValue[0]}&maxArea=${myFilters.areaRangeValue[1]}&photos=${myFilters.photos}&videos=${myFilters.videos}&furnishing_status=${myFilters.furnishing_status?.toString()}&lat=${my_lat[0]}&lng=${my_lat[1]}`,{
                headers: {
                    "Authorization": `Bearer ${_token}`
                }
            });
            if (data?.success) {
                dispatch({
                    type: ALL_PROPERTIES,
                    payload: data?.data
                })
            }
        }
       
    }
    catch (error) {
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
};

export const getHomePageProperties = ( setHomeProperties,centerPoint) => async (dispatch) => {
    try {
        const { data } = await api.get(`/home/?lat=${centerPoint?.lat}&lng=${centerPoint?.lng}`,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        });
        if (data?.success) {
            setHomeProperties(data?.data);
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const createContactOwner = ( _token,userEntry,setInputs) => async (dispatch) => {
    try {
        const { data } = await api.post(`/contact-owner`,userEntry,{
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data?.success) {
            localStorage.setItem("success","1");
            dispatch(successAlertAction("Your response was saved successfully"));
            setInputs({
                name: "",
                email: "",
                phone: "",
              });
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const getUserContactOwner = ( _token,user_id,setMyResponses) => async (dispatch) => {
    try {
        const { data } = await api.get(`/contact-owner/user/${user_id}`,{
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data?.success) {
            setMyResponses(data?.data);
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const getUserLocations = ( _token,setUserLocations) => async (dispatch) => {
    try {
        const { data } = await api.get(`/property/filter-details`,{
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data?.success) {
            setUserLocations(data?.data);
        }
    }
    catch (error) {
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const userNotifications = ( _id, _token,setNotifications) => async (dispatch) => {
    try {
        const { data } = await api.get(`notification/get-user-notifications/${_id}`, {
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data.success) {
            setNotifications(data?.data);
        }
    }
    catch (error) {
        setNotifications([]);
        // localStorage.setItem("error","2");
        // dispatch({
        //     type: ERROR_ALERT,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message
        // })
        
        dispatch(pageLoader(false));
    }
}

export const updateUserNotifications = ( _id,userEntry,handleUserNotificatios) => async (dispatch) => {
    try {
        const { data } = await api.patch(`notification/update-notification/${_id}`, userEntry,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
            handleUserNotificatios();
        }
    }
    catch (error) {
        
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const deleteUserNotifications = ( _id,handleUserNotificatios) => async (dispatch) => {
    try {
        const { data } = await api.delete(`notification/delete-notification/${_id}`,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
            handleUserNotificatios();
        }
    }
    catch (error) {
        
        localStorage.setItem("error","2");
        dispatch({
            type: ERROR_ALERT,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
        
        dispatch(pageLoader(false));
    }
}

export const propertyFilteryDetails = (value) => async (dispatch) => {
    dispatch({
        type: FILTER_DETAILS,
        payload: value,
    });
};

export const addFeedback = ( body,setFeedSuccess,setFeedFailure) => async (dispatch) => {
    try {
        const { data } = await api.post(`/feedback`,body,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
            setFeedSuccess(true);
        }
    }
    catch (error) {
        setFeedFailure(true);        
        dispatch(pageLoader(false));
    }
}

export const addContactUs = ( body,setContactSuccess,setContactFailure,page) => async (dispatch) => {
    try {
        const { data } = await api.post(`/contact-us`,body,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
            if (page === "popup") {
                setContactSuccess(true);
            } else {
                setContactSuccess(true);
                localStorage.setItem("success","1");
                dispatch(successAlertAction("Thanks for contacting us. We'll get back to you shortly."));                
            }

        }
    }
    catch (error) {
        
        setContactFailure(true)
        
        dispatch(pageLoader(false));
    }
}

export const allServiceList = () => async (dispatch) => {
    try {
        const { data } = await api.get(`/services`,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data?.success) {
            dispatch({
                type: SERVICES_LIST,
                payload: data?.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: SERVICES_LIST,
            payload: []
        })
    }
}

export const singleServiceDetail = (_id,setSingleService) => async (dispatch) => {
    try {
        const { data } = await api.get(`/services/${_id}`,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data?.success) {
            setSingleService(data?.data[0]);
        }
    }
    catch (error) {
        setSingleService(null);
    }
}

export const addServiceRequest = ( body,setServiceSuccess,setServiceFailure) => async (dispatch) => {
    try {
        const { data } = await api.post(`/service-request`,body,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
                setServiceSuccess(true);
        }
    }
    catch (error) {
        
        setServiceFailure(true)
        
        dispatch(pageLoader(false));
    }
}