import { Box, Container, Grid, Typography } from "@mui/material";
import AboutUsImg from "../../Assets/Images/about-us.png";
import AboutOne from "../../Assets/Images/about-one.png";
import AboutTwo from "../../Assets/Images/about-two.png";
import AboutThree from "../../Assets/Images/about-three.png";
import AboutFour from "../../Assets/Images/about-four.png";
import classes from "./others.module.css";
import { useSelector } from "react-redux";
import {
  AboutUsIconFive,
  AboutUsIconFour,
  AboutUsIconOne,
  AboutUsIconSeven,
  AboutUsIconSix,
  AboutUsIconThree,
  AboutUsIconTwo,
  WhiteDownArrowIcon,
} from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import FaqList from "./FaqList";
import CountUp from "react-countup";
import { useEffect, useRef, useState } from "react";
import Dropdown from "../Home/Dropdown";

let aboutus_cards = [
  {
    icon: <AboutUsIconOne />,
    title: "Expertise",
    content:
      "Our team consists of seasoned professionals with extensive knowledge of the local market. We leverage this expertise to provide you with personalized advice and tailored solutions.",
  },
  {
    icon: <AboutUsIconTwo />,
    title: "Customer-Centric Approach",
    content:
      "Your satisfaction is our top priority. We take the time to understand your unique needs and preferences, ensuring that we deliver results that exceed your expectations.",
  },
  {
    icon: <AboutUsIconThree />,
    title: "Transparency",
    content:
      "We believe in open and honest communication. From pricing strategies to negotiation tactics, we keep you informed and involved throughout the entire process.",
  },
];

let count_style = {
  width: "2px",
  height: "67px",
  color: "rgba(0,0,0,0.5)",
  display: { xs: "none", sm: "block", md: "block" },
};

function AboutUs() {
  const { ln } = useSelector((state) => state.userLogin);
  const [startCountUp, setStartCountUp] = useState(false);
  const navigate = useNavigate();
  const countUpRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCountUp(true);
          observer.disconnect(); // Stop observing after the count-up is triggered
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    return () => {
      if (countUpRef.current) {
        observer.unobserve(countUpRef.current);
      }
    };
  }, []);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  return (
    <Box bgcolor={"#f8f8f8"}>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
        <Dropdown />
      </Box>
      <Box height={{ xs: "150px", sm: "200px", md: "340px" }}>
        <img src={AboutUsImg} alt="image" width={"100%"} height={"100%"} />
      </Box>
      <Container>
        <Box pt={{ xs: 2, sm: 4, md: 6 }} pb={5}>
          <Grid container>
            <Grid item xs={12} sm={12} md={7}>
              <Box mb={{ xs: 3, sm: 3, md: 0 }}>
                <h3 style={{ fontWeight: "800" }}>
                  About <b style={{ color: "var(--border-colour)" }}>Gazam</b>
                </h3>
                <Typography mt={2}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat. <br />
                  <br />
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={4}>
              <img src={AboutOne} alt="image" width={"100%"} height={"100%"} />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.all_counts} ref={countUpRef}>
          <Box>
            <h3 style={{ fontWeight: "800" }}>
              { startCountUp && <CountUp start={0} end={1750} duration={3} />}
            </h3>
            <Typography>Property Listing</Typography>
          </Box>
          <Typography
            width={"2px"}
            height={"67px"}
            color={"rgba(0,0,0,0.5)"}
            display={{ xs: "none", sm: "block", md: "block" }}
          ></Typography>
          <Box>
            <h3 style={{ fontWeight: "800" }}>{ startCountUp && <CountUp start={0} end={12750} duration={3} />}</h3>
            <Typography>Monthly Searches</Typography>
          </Box>
          <Typography
            width={"2px"}
            height={"67px"}
            color={"rgba(0,0,0,0.5)"}
            display={{ xs: "none", sm: "block", md: "block" }}
          ></Typography>
          <Box>
            <h3 style={{ fontWeight: "800" }}>{ startCountUp && <CountUp start={0} end={850} duration={3} />}</h3>
            <Typography>Owners advertise monthly</Typography>
          </Box>
          <Typography
            width={"2px"}
            height={"67px"}
            color={"rgba(0,0,0,0.5)"}
            display={{ xs: "none", sm: "block", md: "block" }}
          ></Typography>
          <Box>
            <h3 style={{ fontWeight: "800" }}>{ startCountUp && <CountUp start={0} end={1390} duration={3} />}</h3>
            <Typography>Happy customers</Typography>
          </Box>
        </Box>
        <Box pt={5} pb={{ xs: 2, sm: 3, md: 3 }}>
          <Grid container spacing={2}>
            {aboutus_cards.map((datas, i) => (
              <Grid item xs={12} sm={12} md={4} key={i}>
                <Box className={classes.aboutus_card}>
                  {datas.icon}
                  <h5> {datas.title}</h5>
                  <p> {datas.content}</p>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className={classes.aboutus_two}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
              <img src={AboutTwo} alt="image" width={"100%"} height={"100%"} />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-around"}
                height={"100%"}
              >
                <Box display={"flex"} alignItems={"start"} gap={"10px"}>
                  <AboutUsIconFour />
                  <Typography>
                    <b>Buyer Matching :</b> We excel in matching buyers with
                    their ideal properties, ensuring satisfaction on both ends.
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"start"} gap={"10px"}>
                  <AboutUsIconFive />
                  <Typography>
                    <b>Expert Team :</b> Our team comprises seasoned real estate
                    agents who are experts in their field.
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"start"} gap={"10px"}>
                  <AboutUsIconSix />
                  <Typography>
                    <b>Years of Experience :</b> Benefit from our extensive
                    experience in the real estate industry.
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"start"} gap={"10px"}>
                  <AboutUsIconSeven />
                  <Typography>
                    <b>Local Market Knowledge :</b> Rely on our deep
                    understanding of local market trends to make informed
                    decisions.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.aboutus_two}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <img
                src={AboutThree}
                alt="image"
                width={"100%"}
                height={"100%"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-around"}
                height={"100%"}
              >
                <Box mb={{ xs: 3, sm: 3, md: 0 }}>
                  <h5>Lorem Ispum</h5>
                  <Typography>
                    Lorem ipsum dolor sit amet consectetur. Neque tristique
                    eleifend nec egestas malesuada commodo nunc. Rhoncus eu id
                    mattis sed dignissim leo elit at. Pellentesque in netus
                    pretium.
                  </Typography>
                </Box>
                <Box mb={{ xs: 3, sm: 3, md: 0 }}>
                  <h5>Lorem Ispum</h5>
                  <Typography>
                    Lorem ipsum dolor sit amet consectetur. Neque tristique
                    eleifend nec egestas malesuada commodo nunc. Rhoncus eu id
                    mattis sed dignissim leo elit at. Pellentesque in netus
                    pretium. Rhoncus eu id mattis sed dignissim leo elit at.
                  </Typography>
                </Box>
                <Box>
                  <h5>Lorem Ispum</h5>
                  <Typography>
                    Lorem ipsum dolor sit amet consectetur. Neque tristique
                    eleifend nec egestas malesuada commodo nunc. Rhoncus eu id
                    mattis sed dignissim leo elit at.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.aboutus_two}>
          <Grid container>
            <Grid item xs={12} sm={12} md={7}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                height={"100%"}
              >
                <h3
                  style={{ fontWeight: "800", color: "var(--border-colour)" }}
                >
                  Lorem ipsum
                </h3>
                <Typography mt={2}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={4}>
              <img src={AboutFour} alt="image" width={"100%"} height={"100%"} />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.aboutus_two}>
          <FaqList />
        </Box>
      </Container>
    </Box>
  );
}

export default AboutUs;
