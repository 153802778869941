import {
  BackIcon,
  DeleteIconBlack,
  WhatsAppIcon,
} from "../../Assets/Icons/icons";
import AddImage from "../../Assets/Images/add-image.png";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import classes1 from "../Auth/auth.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Numeric } from "../../Utilities/commonFunctions";
import { useNavigate } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import {
  addPhoto,
  createDraftProperty,
  createProperty,
  errorAlertAction,
  updateProperty,
} from "../../Redux/Actions/userActions";
import AddMorePop from "./AddMorePop";

let features = [
  "Separate entry for servant room",
  "No open drainage around",
  "Bank Attached Property",
  "Low Density Society",
];

let water_source = ["Municipal Corporation", "Borewell/Tank", "24*7 water"];

let power_backup = ["None", "Partial", "Full"];

let facing = ["North", "South", "East", "West"];

let advantages = [
  "Close to metro station",
  "Close to school",
  "Close to hospital",
  "Close to mall",
  "Close to market",
  "close to airport",
  "close to railway station",
  " High Ceiling Height",
];

// let all_amenities = [
//   { text: "High Ceiling Height", value: "high_ceiling_height" },
//   { text: "Maintenance staff", value: "maintenance_staff" },
//   { text: "Water storage", value: "water_storage" },
//   { text: "False Ceiling Lighting", value: "false_ceiling_lighting" },
//   { text: "Internet/wi-fi connectivity", value: "internet_wi-fi_connectivity" },
//   { text: "Centrally Air Conditioned", value: "centrally_air_conditioned" },
//   { text: "Visitor parking", value: "visitor_parking" },
//   { text: "Water purifier", value: "water_purifier" },
//   { text: "Security / Fire Alarm", value: "security_fire_alarm" },
//   { text: "Park", value: "park" },
//   { text: "Garden", value: "garden" },
//   { text: "Recently Renovated", value: "recently_renovated" },
//   { text: "Natural Light", value: "natural_light" },
//   { text: "Airy Rooms", value: "airy_rooms" },
//   { text: "Spacious Interiors", value: "spacious_interiors" },
// ];

function PropertyAddAmenities() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const AddAmenitiesDiv = () => {
    const [inputs, setInputs] = useState({
      amenities: [],
      other_features: [],
      water_resources: "",
      power_backup: "",
      facing: "",
      location_advantages: [],
      comments: "",
    });
    const [updateLoader, setUpdateLoader] = useState(false);
    const [uploadedImgs, setUploadedImgs] = useState([]);
    const [allAmenities, setAllAmenities] = useState([]);
    const [popupAmenities, setPopupAmenities] = useState([]);
    const [popupIds, setPopupIds] = useState([]);
    const [allFeatures, setAllFeatures] = useState([]);
    const [addPopup, setAddPopup] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedFeaTureIds, setSelectedFeatureIds] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { propertyData,ln } = useSelector((state) => state.userLogin);
    const userValues = JSON.parse(sessionStorage.getItem("user_values"));
    let _edit = sessionStorage.getItem("edit");
    let _docs = JSON.parse(sessionStorage.getItem("converted_doc_imgs"));
    let my_res = JSON.parse(sessionStorage.getItem("property_info"));

    function handleAllAmenities(e, selected_val, field_name) {
      if (field_name === "amenities") {
        if (e.target.checked) {
          let my_amenities = [...popupAmenities];
          let my_allAmenities = [...allAmenities];
          my_amenities.push(selected_val);
          my_allAmenities.push(selected_val);
          setPopupAmenities(my_amenities);
          setAllAmenities(my_allAmenities);
          setPopupIds([...popupIds, selected_val._id]);
        } else {
          let my_amenities = [...popupAmenities];
          let my_allAmenities = [...allAmenities];
          let my_ids = [...popupIds];
          let filtered = my_amenities.filter(
            (el) => el._id !== selected_val._id
          );
          let filtered_two = my_ids.filter((el) => el !== selected_val._id);
          let filtered_three = my_allAmenities.filter(
            (el) => el._id !== selected_val._id
          );
          setPopupAmenities(filtered);
          setAllAmenities(filtered_three);
          setPopupIds(filtered_two);
        }
      } else if (field_name === "features") {
        if (e.target.checked) {
          let my_features = [...allFeatures];
          my_features.push(selected_val);
          setAllFeatures(my_features);
        } else {
          let my_features = [...allFeatures];
          let filtered = my_features.filter((el) => el !== selected_val);
          setAllFeatures(filtered);
        }
      }
    }
    
    function handleChange(name, value) {
      if (name === "amenities") {
        let my_amenities = inputs.amenities;
        let isExist = my_amenities.find((el) => el._id === value._id);
        let isExist_two = selectedIds.find((el) => el === value._id);
        if (isExist) {
          let filtered = my_amenities.filter((el) => el._id !== value._id);
          setInputs({ ...inputs, amenities: filtered });
        } else {
          my_amenities.push(value);
          setInputs({ ...inputs, amenities: my_amenities });
        }
        if (isExist_two) {
          let filtered_ids = selectedIds.filter((el) => el !== value._id);
          setSelectedIds(filtered_ids);
        } else {
          setSelectedIds([...selectedIds, value._id]);
        }
      } else if (name === "location_advantages") {
        let my_location_advantages = inputs.location_advantages;
        let isExist = my_location_advantages.find((el) => el === value);
        if (isExist) {
          let filtered = my_location_advantages.filter((el) => el !== value);
          setInputs({ ...inputs, location_advantages: filtered });
        } else {
          my_location_advantages.push(value);
          setInputs({ ...inputs, location_advantages: my_location_advantages });
        }
      } else {
        setInputs({ ...inputs, [name]: value });
      }
    }

    function handleChecks(e, name) {
      if (e.target.checked) {
        let my_features = inputs.other_features;
        my_features.push(name._id);
        setInputs({ ...inputs, other_features: my_features });
      } else {
        let my_features = inputs.other_features;
        let filtered = my_features.filter((el) => el !== name._id);
        setInputs({ ...inputs, other_features: filtered });
      }
    }

    function handleBack() {
      let body = {
        amenities: inputs.amenities,
        other_features: inputs.other_features,
        water_source: inputs.water_resources,
        power_backup: inputs.power_backup,
        property_facing: inputs.facing,
        location_advantages: inputs.location_advantages,
        suggest_property_features: inputs.comments,
      };

      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      sessionStorage.setItem(
        "property_info",
        JSON.stringify({ ...my_res, ...body })
      );
      navigate("/propertypricingdetails");
    }

    function handleRegister() {
      let user_details = JSON.parse(localStorage.getItem("userToken")).token;
      let _token = user_details;
      
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let actual_imgs = sessionStorage.getItem("converted_imgs");
      let body = {
        ...my_res,
        amenities: selectedIds,
        other_features: inputs.other_features,
        water_source: inputs.water_resources,
        power_backup: inputs.power_backup,
        property_facing: inputs.facing,
        location_advantages: inputs.location_advantages,
        suggest_property_features: inputs.comments,
        city: my_res?.city,
      };
      let price_convert = body["price"].expected_price?.replaceAll(",","");
      body["price"].expected_price = parseInt(price_convert);
      if (body["expected_time_of_possession"] === "-1") {
        body["expected_time_of_possession"] = "";
      }
      let newly_converted = JSON.parse(actual_imgs);
      let old_converted = propertyData?.property_images?.existingImgs;
      if (newly_converted) {
        body["photos"] = [...old_converted, ...newly_converted];
      } else {
        body["photos"] = [...old_converted];
      }

      if (body["user_type"] === "661cc2f5267fb2f57d21fb8d") {
        body["owner_profile"] =  _docs?.photo ?  _docs?.photo :body["owner_profile"] ;
        body["owner_document"] =  _docs?.doc ?  _docs?.doc :body["owner_document"] ;
      }
      body["property_status"] = "Pending_Approval";
      delete body.property_images;
      
      let split_photos = body["photos"];

      let splited_photos = [];
      let splited_videos = [];
      split_photos.forEach((val)=>{
        if (val.mime_type?.includes("image")) {
          splited_photos.push(val);
        } else {
          let video_val = val;
          if (video_val.image) {
            video_val["video_name"] = video_val.image;
            delete video_val.image;
            splited_videos.push(video_val);
          } else{
            splited_videos.push(video_val);
          }
         
        }
      });
      body["photos"] = splited_photos;
      body["video"] = splited_videos;
      
      if (_edit) {
        delete body._id;
        delete body.__v;
        setUpdateLoader(true);
        dispatch(updateProperty(body, _token, navigate, my_res?._id));
      } else {
        dispatch(createProperty(body, _token, navigate));
      }
    }

    function handleDraft() {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let my_selected_imgs = propertyData?.property_images?.previews;
      let my_selected_videos = propertyData?.property_images?.videos;
      let fresh_arr = [...my_selected_imgs,...my_selected_videos];

      function dataURLtoFile(dataURL, filename) {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }   

      const convertImages = fresh_arr?.map((image) => {
        let up_file = dataURLtoFile(image.image, "image.jpg");
        let my_file = new FormData();
        my_file.append("file", up_file);
        return dispatch(addPhoto(my_file, setUploadedImgs, uploadedImgs,image.type,image.is_cover_image));
      });

      Promise.all(convertImages)
        .then(() => {
          handleSaveDraft();
        })
        .catch((error) => {
          console.error("Error uploading images:", error);
        });
    }

    function handleSaveDraft() {
      let user_details = JSON.parse(localStorage.getItem("userToken"))?.token;
      let _token = user_details;

      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let actual_imgs = sessionStorage.getItem("converted_imgs");
      let body = {
        ...my_res,
        amenities: selectedIds,
        other_features: inputs.other_features,
        water_source: inputs.water_resources,
        power_backup: inputs.power_backup,
        property_facing: inputs.facing,
        location_advantages: inputs.location_advantages,
        suggest_property_features: inputs.comments,
        city: my_res?.city,
      };
      if (body["expected_time_of_possession"] === "-1") {
        body["expected_time_of_possession"] = "";
      }
      let newly_converted = JSON.parse(actual_imgs);
      let old_converted = propertyData?.property_images.existingImgs;
      if (newly_converted) {
        body["photos"] = [...old_converted, ...newly_converted];
      } else {
        body["photos"] = [...old_converted];
      }

      if (body["user_type"] === "661cc2f5267fb2f57d21fb8d") {
        body["owner_profile"] =  _docs?.photo ?  _docs?.photo :body["owner_profile"] ;
        body["owner_document"] =  _docs?.doc ?  _docs?.doc :body["owner_document"] ;
      }
      let price_convert = body["price"].expected_price?.replaceAll(",","");
        body["price"].expected_price = parseInt(price_convert);
      body["property_status"] = "Drafted";
      delete body.property_images;
      let split_photos = body["photos"];

      let splited_photos = [];
      let splited_videos = [];
      split_photos.forEach((val)=>{
        if (val.mime_type?.includes("image")) {
          splited_photos.push(val);
        } else {
          let video_val = val;
          video_val["video_name"] = val.image;
          delete val.image;
          splited_videos.push(video_val);
        }
      });
      body["photos"] = splited_photos;
      body["video"] = splited_videos;

      if (_edit) {
        delete body._id;
        delete body.__v;
        dispatch(updateProperty(body, _token, navigate, my_res?._id));
      } else {
        dispatch(createDraftProperty(body, _token, navigate));
      }
    }

    function handleClose() {
      setAddPopup(false);
    }
    
    function handleAddAmenities() {
      if (popupAmenities.length > 0) {
        setAllAmenities([...allAmenities, ...popupAmenities]);
      }
      handleClose();
    }

    async function handleSubmit() {
      if (
        propertyData?.kind_of_property === "661cde3d267fb2f57d2203e2" &&
        (inputs.amenities.length === 0 ||
          inputs.other_features.length === 0 ||
          inputs.water_resources === "" ||
          inputs.power_backup === "" ||
          inputs.facing === "" ||
          inputs.comments === "" ||
          inputs.location_advantages.length === 0)
      ) {
        localStorage.setItem("error","2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if (
        (propertyData?.property_type === "661e4d8cc2322387fa060526" ||
          propertyData?.property_type === "661e4d97c2322387fa060529") &&
        (inputs.amenities.length === 0 ||
          inputs.other_features.length === 0 ||
          inputs.facing === "" ||
          inputs.comments === "" ||
          inputs.location_advantages.length === 0)
      ) {
        localStorage.setItem("error","2");
        dispatch(errorAlertAction("Please select all fields"));
      } else if (
        (propertyData?.kind_of_property === "661cc347267fb2f57d21fb9a" ||
          propertyData?.kind_of_property === "661cc398267fb2f57d21fba0") &&
        (inputs.amenities.length === 0 ||
          inputs.other_features.length === 0 ||
          inputs.water_resources === "" ||
          inputs.comments === "" ||
          inputs.location_advantages.length === 0)
      ) {
        localStorage.setItem("error","2");
        dispatch(errorAlertAction("Please select all fields"));
      } else {
        let my_res = JSON.parse(sessionStorage.getItem("property_info"));
        let my_selected_imgs = propertyData?.property_images?.previews;
        let my_selected_videos = propertyData?.property_images?.videos;
        let fresh_arr = [...my_selected_imgs,...my_selected_videos];

        function dataURLtoFile(dataURL, filename) {
          const arr = dataURL.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, { type: mime });
        }   

        const convertImages = fresh_arr?.map((image) => {
          let up_file = dataURLtoFile(image.image, "image.jpg");
          let my_file = new FormData();
          my_file.append("file", up_file);
          return dispatch(addPhoto(my_file, setUploadedImgs, uploadedImgs,image.type,image.is_cover_image));
        });

        Promise.all(convertImages)
          .then(() => {
            handleRegister();
          })
          .catch((error) => {
            console.error("Error uploading images:", error);
          });
      }
    }
    // let my_res = JSON.parse(sessionStorage.getItem("property_info"));
    let my_selected_imgs = my_res?.property_images?.payload;

    useEffect(() => {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let saved_amenities = [];
      let emp_arr = [];
      if (my_res) {
        setInputs({
          ...inputs,
          amenities: my_res?.amenities ?? [],
          other_features: my_res?.other_features ?? [],
          water_resources: my_res?.water_source ?? "",
          power_backup: my_res?.power_backup ?? "",
          facing: my_res?.property_facing ?? "",
          location_advantages: my_res?.location_advantages ?? [],
          comments: my_res?.suggest_property_features ?? "",
        });
        saved_amenities = my_res?.amenities ?? [];
        if (my_res?.amenities?.length > 0) {
        
          my_res?.amenities.forEach((val) => {
            if (typeof val === "string") {
              emp_arr.push(val);
            } else {
              emp_arr.push(val._id);
            }
          });
          setSelectedIds(emp_arr);
          setPopupIds(emp_arr);
        }
        setAllFeatures(
          my_res?.other_features ?my_res?.other_features : userValues?.other_features
        );
      }
      let arr = [];

      let initial_amenities = userValues?.amenities.slice(0, 5);
      let initial_features= userValues?.other_features;
      const mergedArray = [...initial_amenities, ...saved_amenities];

      // Create a Map to store unique items based on _id
      let idMap = new Map();
      mergedArray.forEach((item) => {
        idMap.set(item._id, item);
      });

      const uniqueArray = Array.from(idMap.values());
      // let arrtwo = [];
      // uniqueArray.forEach((data) => {
      //   arrtwo.push(data._id);
      // });
      
      // setPopupIds(arrtwo);
      
      if (_edit) {
        let a = userValues?.amenities;
        
        let new_id_arr = [];
        a.forEach((el)=>{
          if (emp_arr.includes(el._id)) {
            new_id_arr.push(el);
          }
        })
        if (new_id_arr.length > 0) {
          setAllAmenities(new_id_arr);
        } else {
          setAllAmenities(initial_amenities);
        }
       
      } else {
        setAllAmenities([...initial_amenities]);
      }
      
      setAllFeatures([...initial_features]);
    }, []);

    // useEffect(()=>{
    //   setSelectedIds([...selectedIds,...popupIds])
    // },[popupIds])

    
    return (
      <>
        <Box className={classes.photos_box}>
          <Typography className={classes.role_box_header} mb={"25px"}>
            <span onClick={handleBack}>
              <BackIcon />
            </span>{" "}
            {_edit
              ? ln && ln["edit"]
                ? ln["edit"]
                : "Edit"
              : ln && ln["add"]
              ? ln["add"]
              : "Add"}{" "}
               {ln && ln["amenities_and_Other_features"]
              ? ln["amenities_and_Other_features"]
              : "Amenities and Other features"}
               
          </Typography>
          <Box>
            <ValidatorForm
              useref="form"
              onSubmit={handleSubmit}
              className={classes.SignUpForm}
            >
              <Box mb={3}>
                <Typography className={classes.my_role}>   {ln && ln["amenities"]
              ? ln["amenities"]
              : "Amenities"} </Typography>
                <Box className={classes.wrapper}>
                  {allAmenities.map((datas, i) => (
                    // selectedIds.includes(datas._id) &&
                    <Typography
                      className={
                        selectedIds.includes(datas._id)
                          ? classes.active_tab
                          : classes.inactive_tab
                      }
                      onClick={() => handleChange("amenities", datas)}
                    >
                      {datas.text}
                    </Typography>
                  ))}
                </Box>
                <Typography
                  style={{ paddingTop: "12px", cursor: "pointer" }}
                  onClick={() => setAddPopup(true)}
                >
                  + {ln && ln["add_more_amenities"]
              ? ln["add_more_amenities"]
              : "Add more amenities"}
                </Typography>
              </Box>
              <Box mb={3} mt={2}>
                <Typography className={classes.my_role}>
                  
                  {ln && ln["other_features"]
              ? ln["other_features"]
              : "Other features"}
                </Typography>
                <Box className={classes.wrapper}>
                  <Grid container spacing={2}>
                    {allFeatures.map((datas) => (
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControlLabel
                          sx={{ height: "30px", alignItems: "start" }}
                          control={
                            <Checkbox
                              checked={inputs.other_features.includes(datas._id)}
                              className={classes.checkbox}
                              onChange={(e) => handleChecks(e, datas)}
                            />
                          }
                          label={datas.text}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
               
              </Box>
              {propertyData?.kind_of_property !== "661cc37f267fb2f57d21fb9d" && (
                <>
                  <Box mb={3} mt={2}>
                    <Typography className={classes.my_role}>
                      {ln && ln["water_source"]
              ? ln["water_source"]
              : " Water source"}
                    </Typography>
                    <Box className={classes.wrapper}>
                      <Grid container spacing={2}>
                        {userValues?.water_source.map((datas) => (
                          <Grid item xs={12} sm={12} md={6}>
                            <Typography
                              className={
                                inputs.water_resources === datas._id
                                  ? classes.active_tab
                                  : classes.inactive_tab
                              }
                              onClick={() =>
                                handleChange("water_resources", datas._id)
                              }
                              minWidth={"80px"}
                            >
                              {datas.text}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>{" "}
                </>
              )}
              {propertyData?.kind_of_property !== "661cc37f267fb2f57d21fb9d" &&
                propertyData?.kind_of_property !== "661cc347267fb2f57d21fb9a" &&
                propertyData?.kind_of_property !== "661cc398267fb2f57d21fba0" && (
                  <Box mb={3} mt={2}>
                    <Typography className={classes.my_role}>
                      
                      {ln && ln["power_backup"]
              ? ln["power_backup"]
              : "Power backup"}
                    </Typography>
                    <Box className={classes.wrapper}>
                      <Grid container spacing={2}>
                        {userValues?.power_backup.map((datas) => (
                          <Grid item xs={4} sm={4} md={4}>
                            <Typography
                              className={
                                inputs.power_backup === datas._id
                                  ? classes.active_tab
                                  : classes.inactive_tab
                              }
                              onClick={() =>
                                handleChange("power_backup", datas._id)
                              }
                              minWidth={"80px"}
                            >
                              {datas.text}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                )}

              {propertyData?.kind_of_property !== "661cc347267fb2f57d21fb9a" &&
                propertyData?.kind_of_property !== "661cc398267fb2f57d21fba0" && (
                  <Box mb={3} mt={2}>
                    <Typography className={classes.my_role}>
                      
                      {ln && ln["property_facing"]
              ? ln["property_facing"]
              : "Property facing"}
                    </Typography>
                    <Box className={classes.wrapper}>
                      <Grid container spacing={2}>
                        {userValues?.property_facing.map((datas) => (
                          <Grid item xs={3} sm={3} md={3}>
                            <Typography
                              className={
                                inputs.facing === datas._id
                                  ? classes.active_tab
                                  : classes.inactive_tab
                              }
                              onClick={() => handleChange("facing", datas._id)}
                              minWidth={"80px"}
                            >
                              {datas.text}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                )}
              <Box mb={3} mt={2}>
                <Typography className={classes.my_role}>
                  
                  {ln && ln["location_advantages"]
              ? ln["location_advantages"]
              : "Location advantages"}
                </Typography>
                <Box className={classes.wrapper}>
                  <Grid container spacing={2}>
                    {userValues?.location_advantages.map((datas) => (
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          className={
                            inputs.location_advantages.includes(datas._id)
                              ? classes.active_tab
                              : classes.inactive_tab
                          }
                          onClick={() =>
                            handleChange("location_advantages", datas._id)
                          }
                          minWidth={"80px"}
                        >
                          {datas.text}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
              <Box mb={2}>
                <Typography className={classes.my_role}>
                  
                  {ln && ln["suggest_property_features"]
              ? ln["suggest_property_features"]
              : "Suggest property features"}
                </Typography>

                <TextValidator
                  variant="outlined"
                  multiline
                  rows={2}
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Enter features/add some descriptions..."
                  className={`${classes.formInputFeild} my-field`}
                  name="comments"
                  value={inputs.comments}
                  size="normal"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <TextField select>
                  //         <MenuItem>+91</MenuItem>
                  //         <MenuItem>+91</MenuItem>
                  //       </TextField>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required"]} // Add this line
                  errorMessages={["Property features is required"]}
                />
              </Box>

              <Box marginTop="40px" className="d-flex" gap="20px">
              { my_res?.property_status === "Drafted"  && 
                <Button fullWidth className={classes.draft_btn} onClick={handleDraft} >
                {ln && ln["save_as_draft"] ? ln["save_as_draft"] : "Save as draft"}
                </Button> }
                <Button fullWidth className={classes.continue} type="submit" 
                endIcon={updateLoader && <CircularProgress size={20} sx={{color:"#fff"}} />}
                >
                {_edit ? "Update" : "Create"}
                </Button>
              </Box>
            </ValidatorForm>
          </Box>
        </Box>
        <AddMorePop
          open={addPopup}
          handleClose={handleClose}
          arrOne={userValues}
          allAmenities={allAmenities}
          allFeatures={allFeatures}
          handleAllAmenities={handleAllAmenities}
          arrTwo={features}
          popupIds={popupIds}
          handleAddAmenities={handleAddAmenities}
        />
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<AddAmenitiesDiv />} stepper={2} />
    </>
  );
}

export default PropertyAddAmenities;
