import { Box, Button, Container, Grid, Typography } from "@mui/material";
import NriLogo from "../../Assets/Images/nri-logo.png";
import NriImg from "../../Assets/Images/nri-img.png";
import ServiceOne from "../../Assets/Images/service-one.png";
import ServiceTwo from "../../Assets/Images/service-two.png";
import ServiceThree from "../../Assets/Images/service-three.png";
import ServiceFour from "../../Assets/Images/service-four.png";
import ServiceFive from "../../Assets/Images/service-five.png";
import ServiceSix from "../../Assets/Images/service-six.png";
import classes from "./others.module.css";
import {
  ContactSupportIcon,
  LiteGreenCheckIcon,
} from "../../Assets/Icons/icons";
import PostEnquiryPop from "../popup/PostEnquiryPop";
import { useEffect, useState } from "react";
import FaqList from "./FaqList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { singleServiceDetail } from "../../Redux/Actions/userActions";
import AddServicePop from "../popup/AddServicePop";

let _services = [
  {
    img: ServiceOne,
    title: "Legal Opinion",
  },
  {
    img: ServiceTwo,
    title: "Land Survey",
  },
  {
    img: ServiceThree,
    title: "Building Permissions",
  },
  {
    img: ServiceFour,
    title: "Land monitoring services",
  },
  {
    img: ServiceFive,
    title: "Layout Approvals",
  },
  {
    img: ServiceSix,
    title: "Land Development services",
  },
];

function NriServices() {
  const [enquiryPopup, setEnquiryPopup] = useState(false);
  const [servicePop, setServicePop] = useState(false);
  const [singleService, setSingleService] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  // const serviceId = location?.state?.service_id;
  const serviceId = params?.id;

  const handleClose = () => {
    setEnquiryPopup(false);
  };
  console.log("params =",params)
  function handleGetSingleService() {
    if (serviceId) {
      dispatch(singleServiceDetail(serviceId,setSingleService));
    }
  }

  function handleNewService(datas) {
    navigate(`/nri-services/${datas?._id}`);
  }

  

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    handleGetSingleService();
  }, [serviceId]);

  console.log("singleService = ",singleService)
  return (
    <Box bgcolor={"#f8f8f8"} pb={4}>
      <Container>
        <Box className={classes.header_part}>
          <Box>
            <h2>{singleService?.name}</h2>
            <span>Tailored real estate services, just for you !</span>
          </Box>
          <img src={NriLogo} alt="image" />
        </Box>
        <Box className={classes.nri_card}>
          <Grid container>
            <Grid item xs={6} sm={6} md={6}>
              <Box className={classes.card_info}>
                <Typography>
                  <b>Dedicated Relationship Manager </b>for you to help you sell
                  your property faster and clear legal services for you
                </Typography>
                <Button
                  sx={{
                    display: {
                      xs: "none",
                      sm: "inline-block",
                      md: "inline-block",
                    },
                  }}
                >
                  <ContactSupportIcon /> Contact Support
                </Button>
                <Button
                  sx={{
                    display: { xs: "inline-block", sm: "none", md: "none" },
                  }}
                >
                  Contact Support
                </Button>
              </Box>
            </Grid>
            <Grid item xs={0} sm={2} md={3}></Grid>
            <Grid item xs={6} sm={4} md={2}>
              <img src={NriImg} alt="image" />
            </Grid>
            <Grid item xs={0} sm={0} md={1}></Grid>
          </Grid>
        </Box>
        <Box className={classes.we_provides}>
          <h3 style={{ color: "#fff" }}>We Provides</h3>
          <Box className={classes.we_provides_details}>
            {Array.isArray(singleService?.features) &&
            singleService?.features?.map((val) => (
              <Typography>
                <LiteGreenCheckIcon /> {val}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box mt={7}>
          <Typography fontWeight={"800"} fontSize={"25px"}>
            Explore Our Services{" "}
          </Typography>
          <Grid container>
            {Array.isArray(singleService?.otherServices) &&
            singleService?.otherServices?.map((datas) => (
              <Grid item xs={6} sm={4} md={2}>
                <Box textAlign={"center"} onClick={()=>handleNewService(datas)} >
                  <img src={`${process.env.REACT_APP_API_URL}${datas?.image}`} alt="image" width={"90%"} />
                  <Typography
                    width={"80%"}
                    margin={"auto"}
                    fontSize={"18px"}
                    fontWeight={"500"}
                    mt={1}
                  >
                    {datas.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={7}>
         {singleService?.faqList?.length > 0 &&
          <FaqList allFaqs={singleService?.faqList}/>}
          <Button
            className={classes.post_enquiry}
            onClick={() => setServicePop(true)}
          >
            Post an Enquiry
          </Button>
        </Box>
      </Container>

      <PostEnquiryPop open={enquiryPopup} handleClose={handleClose} />
      {singleService &&
      <AddServicePop
        open={servicePop}
        handleClose={()=>setServicePop(false)}
        serviceName={singleService?.name}
        serviceId={singleService?._id}
      />}
    </Box>
  );
}

export default NriServices;
