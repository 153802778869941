import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./popup.css";
import { LuAlertOctagon } from "react-icons/lu";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import classes from "../property/property.module.css";
import { CloseIconGreen } from "../../Assets/Icons/icons";
import { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { addContactUs } from "../../Redux/Actions/userActions";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";

let our_services = [
  "Legal Opinion",
  "Land Survey",
  "Building Permission",
  "Land Monitoring Services",
  "Layout Approvals",
  "Land Development services",
  "Relationship Manager (NRI)",
];

function PostEnquiryPop(props) {
  const { open, handleClose } = props;

  const [postEnqSuccess, setPostEnqSuccess] = useState(false);
  const [postEnqFailure, setPostEnqFailure] = useState(false);
  const [postEnqInfo, setPostEnqInfo] = useState({
    message: "",
    email: "",
    name: "",
    phone: "",
  });
  const dispatch = useDispatch();

  function handlePopClose() {
    handleClose();
    setPostEnqInfo({
      message: "",
      email: "",
      name: "",
      phone: "",
    });
    setPostEnqFailure(false);
    setPostEnqSuccess(false);
  }

  function handleChange(e) {
    const { value, name } = e.target;
    setPostEnqInfo({ ...postEnqInfo, [name]: value });
  }

  function handleAddEnquiry() {
    if (postEnqInfo.email === "-1") {
      
    } else {
      let body = { ...postEnqInfo };
      dispatch(addContactUs(body, setPostEnqSuccess,setPostEnqFailure,"popup"));
    }
  }

  useEffect(() => {
    if (postEnqSuccess) {
      setTimeout(() => {
        handlePopClose();
      }, 2500);
    }
  }, [postEnqSuccess]);

  useEffect(() => {
    if (postEnqFailure) {
      setTimeout(() => {
        setPostEnqFailure(false)
      }, 2500);
    }
  }, [postEnqFailure]);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="post-enquiry"
      >
        <Box>
          <Box className="d-flex-between popup-header" flexWrap={"wrap"}>
            <Typography
              fontSize={"23px"}
              fontWeight={"600"}
              sx={{ color: "var(--border-colour)" }}
            >
              Enquiry
            </Typography>
            <span onClick={handlePopClose}>
              <CloseIconGreen className={"contact-close"} />
            </span>
          </Box>
          <Box p={3} pt={1}>
            <ValidatorForm
              useref="form"
              onSubmit={handleAddEnquiry}
              className={classes.SignUpForm}
            >
              <Box className="name-phone-wrapper">
              <Box mb={2}>
                <Typography className="enquiry-title">Name</Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="eg: arun"
                  className={`${classes.formInputFeild} my-field`}
                  name="name"
                  value={postEnqInfo.name}
                  size="normal"
                  onChange={handleChange}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required"]}
                  errorMessages={[
                    "Name is required"
                  ]}
                />
              </Box>
              <Box mb={2}>
                <Typography className="enquiry-title">Phone Number</Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="eg: 8615222330"
                  className={`${classes.formInputFeild} my-field`}
                  name="phone"
                  value={postEnqInfo.phone}
                  size="normal"
                  onChange={handleChange}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required", "matchRegexp:[0-9]{10}"]}
                  errorMessages={[
                    "Phone Number is required",
                    "Enter 10 digit valid Phone number",
                  ]}
                />
              </Box>
              </Box>
              <Box mb={2}>
                <Typography className="enquiry-title">Email Id (optional)</Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="eg: vijay@gmail.com"
                  className={`${classes.formInputFeild} my-field`}
                  name="email"
                  value={postEnqInfo.email}
                  size="normal"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <Typography className="enquiry-title">Enter details</Typography>
                <TextValidator
                  variant="outlined"
                  multiline
                  rows={2}
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Please enter your queries"
                  className={`${classes.formInputFeild} my-field`}
                  name="message"
                    value={postEnqInfo.message}
                  size="normal"
                  onChange={handleChange}
                  validators={["required"]} // Add this line
                  errorMessages={["Message is required"]}
                />
              </Box>
              <Box
                marginTop="20px"
                display={"flex"}
                alignItems={"center"}
                gap="20px"
              >
                <Button className={"add-services-submit"} px={4} type="submit">
                  Submit
                </Button>
              </Box>
              {postEnqSuccess && (
                  <Typography
                    className="feedback-success d-flex-center"
                    gap={"8px"}
                  >
                    <FaCircleCheck /> Enquiry Added Successfully
                  </Typography>
                )}
                {postEnqFailure && (
                  <Typography
                    className="feedback-failure d-flex-center"
                    gap={"8px"}
                  >
                   <IoCloseCircle />  Something went wrong. Try again.
                  </Typography>
                )}
            </ValidatorForm>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default PostEnquiryPop;
