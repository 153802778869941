module.exports = {
    USER_LOGIN_REQUEST_SUCCESS: "USER_LOGIN_REQUEST_SUCCESS",
    USER_LOGIN_REQUEST_FAIL: "USER_LOGIN_REQUEST_FAIL",
    HEADER_NAME: "HEADER_NAME",
    PAGE_LOADER: "PAGE_LOADER",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    USER_REGISTER_REQUEST_SUCCESS: "USER_REGISTER_REQUEST_SUCCESS",
    USER_REGISTER_REQUEST_FAIL: "USER_REGISTER_REQUEST_FAIL",
    OTP_VERIFICATION_REQUEST_SUCCESS: "OTP_VERIFICATION_REQUEST_SUCCESS",
    OTP_VERIFICATION_REQUEST_FAIL: "OTP_VERIFICATION_REQUEST_FAIL",
    RESEND_OTP_VALUE: "RESEND_OTP_VALUE",
    GET_LANGUAGE_DATA_SUCCESS: "GET_LANGUAGE_DATA_SUCCESS",
    GET_LANGUAGE_DATA_FAIL: "GET_LANGUAGE_DATA_FAIL",
    PROPERTY_DATA: "PROPERTY_DATA",
    ERROR_ALERT: "ERROR_ALERT",
    SUCCESS_ALERT: "SUCCESS_ALERT",
    EMPTY_PROPERTY_DATA: "EMPTY_PROPERTY_DATA",
    ALL_PROPERTIES: "ALL_PROPERTIES",
    CLEAR_PROPERTY_DATA: "CLEAR_PROPERTY_DATA",
    FILTER_DETAILS: "FILTER_DETAILS",
    SERVICES_LIST: "SERVICES_LIST",
}