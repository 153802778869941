import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Radio,
} from "@mui/material";
import "./popup.css";
import { CloseIconGreen, ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import classes from "../property/property.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { addFeedback } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";

let _feedbacks = [
  "I want to report a problem",
  "I have a suggestion",
  "I want to compliment the app",
  "Other",
];

function FeedbackPop(props) {
  const { open, handleClose } = props;

  const [feedTypeErr, setFeedTypeErr] = useState(false);
  const [feedSuccess, setFeedSuccess] = useState(false);
  const [feedFailure, setFeedFailure] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({
    feedback_type: "-1",
    feedback_description: "",
    email: "",
    name: "",
    phone: "",
  });
  const dispatch = useDispatch();

  function handlePopClose() {
    handleClose();
    setFeedbackInfo({
      feedback_type: "-1",
      feedback_description: "",
      email: "",
      name: "",
      phone: "",
    });
    setFeedFailure(false);
    setFeedSuccess(false);
    setFeedTypeErr(false);
  }

  function handleChange(e) {
    const { value, name } = e.target;
    setFeedbackInfo({ ...feedbackInfo, [name]: value });
  }

  function handleAddFeedback() {
    if (feedbackInfo.feedback_type === "-1") {
      setFeedTypeErr(true);
    } else {
      let body = { ...feedbackInfo };
      dispatch(addFeedback(body, setFeedSuccess,setFeedFailure));
    }
  }

  useEffect(() => {
    if (feedSuccess) {
      setTimeout(() => {
        handlePopClose();
      }, 2500);
    }
  }, [feedSuccess]);

  useEffect(() => {
    if (feedFailure) {
      setTimeout(() => {
        setFeedFailure(false)
      }, 2500);
    }
  }, [feedFailure]);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="feedback-pop"
      >
        <Box>
          <Typography
            sx={{ cursor: "pointer" }}
            textAlign={"end"}
            onClick={handlePopClose}
          >
            <CloseIconGreen />
          </Typography>
          <Box px={2}>
            <Typography
              fontSize={"23px"}
              fontWeight={"600"}
              sx={{ color: "var(--border-colour)" }}
            >
              Feedbacks
            </Typography>
            <Typography>Share your feedback below</Typography>
            <Box mt={3}>
              <ValidatorForm
                useref="form"
                onSubmit={handleAddFeedback}
                className={classes.SignUpForm}
              >
                <Box mb={3}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    select
                    id="emailInput"
                    placeholder="Expected by"
                    className={`${classes.formInputFeild} my-field-two select-field`}
                    name="feedback_type"
                    value={feedbackInfo.feedback_type}
                    size="normal"
                    onChange={(e) => {
                      setFeedTypeErr(false);
                      handleChange(e);
                    }}
                    validators={["required"]} // Add this line
                    errorMessages={[true && "Description is required"]}
                    // helperText={"ssss"}
                  >
                    <MenuItem value={"-1"} hidden>
                      <p style={{ color: "rgba(0,0,0,0.3)" }}>
                        Select Feedback
                      </p>
                    </MenuItem>
                    {_feedbacks.map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          className="feedback-type-list"
                          // onClick={(e) =>
                          //   handleChange(
                          //     "expected_time_of_possession",
                          //     item._id
                          //   )
                          // }
                        >
                          <p className={`${classes.selectAge}`}>{item}</p>
                        </MenuItem>
                      );
                    })}
                  </TextValidator>
                  {feedTypeErr && (
                    <span className="feedback-type">
                      Feedback type is required
                    </span>
                  )}
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    multiline
                    rows={2}
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Write your feedback"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="feedback_description"
                    onChange={handleChange}
                    value={feedbackInfo.feedback_description}
                    size="normal"
                    validators={["required"]} // Add this line
                    errorMessages={["Description is required"]}
                  />
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Your Name"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="name"
                    value={feedbackInfo.name}
                    size="normal"
                    onChange={handleChange}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required"]} // Add this line
                    errorMessages={["Name is required"]}
                  />
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Your Email"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="email"
                    value={feedbackInfo.email}
                    size="normal"
                    onChange={handleChange}
                    validators={["required", "isEmail"]} // Add this line
                    errorMessages={[
                      "Email is required",
                      "Enter Valid email ID",
                    ]}
                  />
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Your Phone"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="phone"
                    value={feedbackInfo.phone}
                    size="normal"
                    onChange={handleChange}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                  />
                </Box>

                <Box marginTop="20px">
                  <Button
                    fullWidth
                    className={"add-services-submit"}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
                {feedSuccess && (
                  <Typography
                    className="feedback-success d-flex-center"
                    gap={"8px"}
                  >
                    <FaCircleCheck /> Feedback Added Successfully
                  </Typography>
                )}
                {feedFailure && (
                  <Typography
                    className="feedback-failure d-flex-center"
                    gap={"8px"}
                  >
                   <IoCloseCircle />  Something went wrong. Try again.
                  </Typography>
                )}
              </ValidatorForm>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default FeedbackPop;
