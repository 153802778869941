import {
  Box,
  Dialog,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button
} from "@mui/material";
import classes from "./property.module.css";
import "./property.css";
import { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

function AddMorePop(props) {
  const { open, handleClose, arrOne,allAmenities,allFeatures,handleAllAmenities,arrTwo,popupIds,handleAddAmenities } = props;
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="add-more-items"
      >
        <Box>
        <Box
            className="d-flex-between main-title"
            flexWrap={"wrap"}
          >
            <Typography fontSize={"20px"}>All Amenities</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>
          <Box p={3}>
            <Typography className="sub-title">Property features</Typography>
            {arrOne?.amenities.slice(5).map((datas) => (
                <Typography my={1} >
                    <FormControlLabel
                  sx={{ height: "30px", alignItems: "start" }}
                  control={
                    <Checkbox
                      checked={popupIds.includes(datas._id)}
                      className="checkbox"
                      onChange={(e) => handleAllAmenities(e, datas,"amenities")}
                    />
                  }
                  label={datas.text}
                />
                </Typography>
            ))}
          </Box>
          {/* <Box mt={2} >
            <Typography className="sub-title">Other Features</Typography>
            {arrTwo.map((datas) => (
                <Typography my={1} >
                    <FormControlLabel
                  sx={{ height: "30px", alignItems: "start" }}
                  control={
                    <Checkbox
                      checked={allFeatures.includes(datas)}
                      className="checkbox"
                      onChange={(e) => handleAllAmenities(e, datas,"features")}
                    />
                  }
                  label={datas}
                />
                </Typography>
            ))}
          </Box> */}
          {/* <Button onClick={handleAddAmenities} className={classes.register_btn} >
            add
          </Button> */}
        </Box>
      </Dialog>
    </>
  );
}

export default AddMorePop;
