import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Popover,
  Tabs,
  Tab,
  Grid,
  Chip,
  Fab,
  useScrollTrigger,
  Fade,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  OutlinedInput,
  Checkbox,
  Drawer,
  FormControlLabel,
  Switch,
  InputLabel,
  FormControl,
  Pagination,
} from "@mui/material";
import classes from "./home.module.css";
import "./home.css";
import BuildingOne from "../../Assets/Images/building-1.png";
import AdImg from "../../Assets/Images/ad.png";
import BuildingTwo from "../../Assets/Images/building-2.png";
import BuildingThree from "../../Assets/Images/building-3.png";
import {
  ChatIcon,
  ContactIcon,
  ExpandMoreIcon,
  HeartIcon,
  SearchIcon,
  WhatsappIcon,
} from "../../Assets/Icons/icons";
import {
  addWishlist,
  getAllHomeProperties,
  getAllValues,
  getUserLocations,
} from "../../Redux/Actions/userActions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../Utilities/commonFunctions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ContactPop from "../popup/ContactPop";
import Loader from "../popup/Loader";
import { IoIosCloseCircle } from "react-icons/io";
import {
  LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import { FILTER_DETAILS } from "../../Redux/Constants/userConstants";
import { IoIosSearch } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import ContactOwnerPop from "../popup/ContactOwnerPop";
import AlertBox from "../../Components/AlertBox";

let highlights = ["Big Parking", "Playground", "Open Balcony"];
let dummy_arr = [
  "Apartment",
  "Flat",
  "House",
  "Independent Floor",
  "Plot",
  "Duplex",
  "Penthouse",
];
let arr = [1, 2, 3, 4, 5];

const MAX = 0;
const MIN = 100000000;
const marks = [
  {
    value: MIN,
    label: "",
  },
  {
    value: MAX,
    label: "",
  },
];

const MAX_2 = 0;
const MIN_2 = 10000;
const marks_2 = [
  {
    value: MIN_2,
    label: "",
  },
  {
    value: MAX_2,
    label: "",
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  { id: 1, label: "Option 1" },
  { id: 2, label: "Option 2" },
  { id: 3, label: "Option 3" },
  { id: 4, label: "Option 4" },
];

function SearchResults() {
  const [heart, setHeart] = useState(false);
  const [rangeValue, setRangeValue] = useState([0, 100000000]);
  const [areaRangeValue, setAreaRangeValue] = useState([1300, 4500]);
  const [expanded, setExpanded] = useState("");
  const [myProperties, setMyProperties] = useState({
    properties: [],
    TotalCount: 0,
  });
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [myFilters, setMyFilters] = useState({
    property_type: [],
    kind_of_property: [],
    user_type: [],
    localities: [],
    amenities: [],
    amenities_val: [],
    listing: "createdAt-dsc",
    price: "",
    availability: [],
    rangeValue: [0, 100000000],
    areaRangeValue: [0, 10000],
    photos: false,
    videos: false,
    furnishing_status: [],
    status: 0,
    room_type:[],
    isRera:false,
    isVerified:false,
    page:1
  });
  const [previewFilters, setPreviewFilters] = useState([]);
  const [selectedSort, setSelectedSort] = useState("relevance");
  const [userLocations, setUserLocations] = useState({});
  const [userValues, setUserValues] = useState({});
  const [newAllProperties, setNewAllProperties] = useState({properties:[],TotalCount:"0"});
  const [contactPopup, setContactPopup] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filterBar, setFilterBar] = useState(false);
  const [loginErr, setLoginErr] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const my_params = useParams();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let user_values = JSON.parse(sessionStorage.getItem("user_values"));
  const { allProperties, filterDetails } = useSelector(
    (state) => state.userLogin
  );
  let _clicked = sessionStorage.getItem("clicked-val");
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [searchCoordinates, setSearchCoordinates] = useState(null);
  const [searchPropertyResults, setSearchPropertyResults] = useState({properties:[],TotalCount:0});
  const [sorting, setSortings] = useState(1);

  
  const handleGetContact = (e)=>{
    const {name,value} = e.target;
    setGetContact({...getContact,[name]:value});
  }


  const handleCheckBoxs = (e) => {
    const { name, checked } = e.target;
    if (name === "photos") {
      if (checked) {
        setMyFilters({ ...myFilters, photos: true });
      } else {
        setMyFilters({ ...myFilters, photos: false });
      }
    } else {
      if (checked) {
        setMyFilters({ ...myFilters, videos: true });
      } else {
        setMyFilters({ ...myFilters, videos: false });
      }
    }
  };

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  const handleUserLocations = () => {
    dispatch(getUserLocations(_token, setUserLocations));
  };

  const handleChange = (field, val,name) => {
    if (field === "localities") {
      let arr = typeof val === "string" ? val.split(",") : val;
      setMyFilters({ ...myFilters, [field]: arr });
    } else {
      let selected_field = myFilters[field];
      let my_preview_data = [...previewFilters];

      if (selected_field.includes(val)) {
        let removed = myFilters[field].filter((el) => el !== val);
        setMyFilters({ ...myFilters, [field]: removed });
        let filter_pre = my_preview_data?.filter((el)=>el.id !== val);
        setPreviewFilters(filter_pre);
      } else {
        let existing = [...myFilters[field], val];
        setMyFilters({ ...myFilters, [field]: existing });
        let obj = {name:field,val:name,id:val};
        my_preview_data.push(obj);
        setPreviewFilters(my_preview_data)
      }
    }
  };

  const handleAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleRangeChange = (event, newValue) => {
    setMyFilters({ ...myFilters, rangeValue: newValue });
  };

  const handleAreaChange = (event, newValue) => {
    setMyFilters({ ...myFilters, areaRangeValue: newValue });
  };

  const handleSwitchChange = (fieldName) => {
    setMyFilters({ ...myFilters, [fieldName]: !myFilters[fieldName] });
  };

  const handleAllProperties = () => {
    if (myFilters?.status != 0) {
      setIsLoading(true);
      dispatch(getAllHomeProperties(_token, myFilters, my_params?.id));
      setIsLoading(false);
    }
  };

  function handleGetValues() {
    dispatch(getAllValues(setUserValues));
  }

  const handleClearAll = () => {
    setMyFilters({
      property_type: [],
      kind_of_property: [],
      user_type: [],
      localities: [],
      amenities: [],
      amenities_val: [],
      listing: "createdAt-dsc",
      price: "",
      availability: [],
      rangeValue: [0, 100000000],
      areaRangeValue: [0, 10000],
      furnishing_status: [],
      photos: false,
      videos: false,
      status: 1,
      room_type:[],
      isRera:false,
      isVerified:false,
      page:1
    });
    setPreviewFilters([]);
    handleAllProperties();
  };

  function handleWishlist(id) {
    if (_token) {
      dispatch(addWishlist(heart, setHeart, id, handleAllProperties));
    } else {
      setLoginErr("Please login to add Whishlist");
    }
  
    // setTimeout(() => {
    // handleAllProperties();
    // }, 1000);
  }

  // useEffect(() => {
  //   handleAllProperties();
  // }, [myFilters]);

  useEffect(() => {
    if (my_params?.id !== "allproperties") {
      let my_lat = my_params?.id?.split(",");
      setMyFilters({ ...myFilters, lat: my_lat[0], lng: my_lat[1] });
    }
    handleGetValues();
    handleUserLocations();

    localStorage.removeItem("error");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange1 = (event, f_name, _id) => {
    const {
      target: { value },
    } = event;
    let last_val = event.target.value;
    if (f_name === "localities") {
      setMyFilters({ ...myFilters, localities: event.target.value });
      let a = [...previewFilters,{name:"localities",val: last_val[last_val.length -1] }];
      setPreviewFilters(a);
    } else if (f_name === "amenities") {
      setMyFilters({ ...myFilters, amenities_val: event.target.value });
    }
  };

  function handleDeletePreview(chip_val) {
    let my_previews = previewFilters;
    let remove_data = my_previews?.filter((el)=> el.val !== chip_val?.val);
    setPreviewFilters(remove_data);

    if (chip_val?.name === "amenities_val") {
      let selected_fields = myFilters["amenities_val"];
      let selected_fields_ids = myFilters["amenities"];
      let filter_datas = selected_fields?.filter((el)=>el !== chip_val?.val);
      let filter_datas_ids = selected_fields_ids?.filter((el)=>el !== chip_val?.id);
      setMyFilters({...myFilters,amenities_val:filter_datas,amenities:filter_datas_ids});

    } else  if (chip_val?.name === "localities") {
      let selected_fields = myFilters[chip_val?.name];
      let filter_datas = selected_fields?.filter((el)=>el !== chip_val?.val);
      setMyFilters({...myFilters,[chip_val?.name]:filter_datas});
    } else {
      let selected_fields = myFilters[chip_val?.name];
      
      let filter_datas = selected_fields?.filter((el)=>el !== chip_val?.id);
      setMyFilters({...myFilters,[chip_val?.name]:filter_datas});
    }

  }

  const handleAmenities = (option, _id) => {
    let _exist = myFilters.amenities;
    let _exist_val = myFilters.amenities_val;
    let new_exist = _exist.find((el) => el === _id);
    let _previews = previewFilters;

    if (new_exist) {
      let filter_id = _exist.filter((el) => el !== _id);
      let filter_val = _exist_val.filter((el) => el !== option.text);
      setMyFilters({
        ...myFilters,
        amenities: filter_id,
        amenities_val: filter_val,
      });
      let filter_preview = _previews.filter((el)=>el.val !== option.text);
      
      setPreviewFilters(filter_preview);
    } else {
      let a = [..._exist, _id];
      let b = [..._exist_val, option.text];

      setMyFilters({ ...myFilters, amenities: a, amenities_val: b });
      let c = [...previewFilters,{name:"amenities_val",val: option.text,id:_id }];
      setPreviewFilters(c);
    }
  };

  const handleLocalities = (option) => {
    let _exist_val = myFilters.localities;
    let new_exist = _exist_val.find((el) => el === option);
    let _previews = previewFilters;

    if (new_exist) {
      let filter_val = _exist_val.filter((el) => el !== option);
      setMyFilters({
        ...myFilters,
        localities: filter_val,
      });
      let filter_preview = _previews.filter((el)=> el.val !== option);
      
      setPreviewFilters(filter_preview);
    } 
    else {
      let a = [..._exist_val, option];

      setMyFilters({ ...myFilters, localities: a });
      let c = [...previewFilters,{name:"localities",val: option,id:"" }];
      setPreviewFilters(c);
    }
  };

  function handleSelectCover(photos) {
    let property_photos = photos;
    let cover_img = "";

    property_photos.forEach((val) => {
      if (val?.is_cover_image) {
        cover_img = val?.image;
      } else {
        if (cover_img === "") {
          cover_img = val?.image;
        }
      }
    });

    return `${process.env.REACT_APP_API_URL}/${cover_img}`;
  }
  
  function handleSliderValue(val) {
    let format_numbers = formatNumber(val);
    return format_numbers;
  }

  const handlePageChange = (event, value) => {
    setMyFilters({...myFilters,page:value});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  function handlePlaceChanged() {
    if (autocomplete !== null) {
      const place = autocomplete?.getPlace();
      if (place && place.formatted_address) {
        setInputValue(place.formatted_address);
        const location = place?.geometry?.location;
        if (location) {
          const lat = location?.lat();
          const lng = location?.lng();
          setSearchCoordinates({ lat, lng });
        } else {
          alert("Try again");
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  async function handleWhatsAppClicked(user_detail) {

    let _content = `Hai ${user_detail?.name},`;

    const prefilledMessage = await encodeURIComponent(_content);

    const whatsappURL = `https://wa.me/${user_detail?.phone}?text=${prefilledMessage}`;

    setTimeout(()=>{
      window.open(whatsappURL, '_blank');
    },1000)
  }

  useEffect(()=>{
    let searched_val = localStorage.getItem("search-input");
    if (_clicked && _clicked != "Property in High Demands" && !searched_val) {
      let my_props = allProperties?.properties;
      let fill_props = my_props?.filter((el)=>el.kind_of_property == _clicked);

      let a = {
        properties:fill_props,
        TotalCount:fill_props?.length
      };
      setNewAllProperties(a);

    } else {
      setNewAllProperties(allProperties);
    }
  },[allProperties])
  
  useEffect(() => {
    if (autocomplete && inputRef.current) {
      autocomplete.addListener("place_changed", handlePlaceChanged);
    }

    return () => {
      if (autocomplete && inputRef.current) {
        window?.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [autocomplete]);

  useEffect(() => {
    let searched_val = localStorage.getItem("search-input");
    setInputValue(searched_val ?? "");
  }, [window.location.pathname]);

  useEffect(() => {
    if (my_params?.id !== "allproperties") {
      let my_lat = my_params?.id?.split(",");

      if (my_lat) {
        setSearchCoordinates({ lat: my_lat[0], lng: my_lat[1] });
      }
    } else {
      setSearchCoordinates(null);
    }
  }, []);

  useEffect(() => {
    if (filterDetails) {
      setMyFilters({ ...myFilters, ...filterDetails, status: 1 });
    } else {
      setMyFilters({ ...myFilters, status: 1 });
    }
  }, []);

  useEffect(() => {
    handleAllProperties();

    let name_values = [...myFilters?.localities,...myFilters?.amenities_val];

    // setPreviewFilters(name_values);

  }, [myFilters, window.location.pathname]);

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({...getContact,name:Con_details?.name,phone:Con_details?.phone,email:Con_details?.email})
    }
  }, []);
  

  const DrawerList = (
    <Box className={classes.filter_part} overflow={"hidden"}>
      <Box className="d-flex-between" py={1} px={2} position={"relative"}>
        <Typography fontSize={"20px"}>Filters</Typography>
        <Button
          size="small"
          className={classes.clear_btn}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
        <IoIosCloseCircle
          style={{ fontSize: "26px" }}
          onClick={() => setFilterBar(false)}
        />
      </Box>
      <hr />
      {Array.isArray(previewFilters) &&
                previewFilters.length > 0 &&
                <Box className={classes.filter_preview} >
                {previewFilters.map((el,i)=>(
                  
                <Chip
                  label={el.val}
                  // onClick={handleClick}
                  onDelete={()=>handleDeletePreview(el)}
                  key={i}
                />
               
                ))}

                </Box>
                }
      <Box p={2}>
        <Typography fontSize={"20px"}>Budget</Typography>
        <Box mt={2} className="price-slider">
          <Box className="d-flex-between">
            <Typography>₹ {formatNumber(myFilters.rangeValue[0])}</Typography>
            <Typography>₹ {formatNumber(myFilters.rangeValue[1])}</Typography>
          </Box>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={myFilters.rangeValue}
            onChange={handleRangeChange}
            // valueLabelDisplay="auto"
            min={0}
            max={100000000}
            step={15}
            marks={marks}
            // valueLabelFormat={handleSliderValue}
          />
        </Box>
        <Box className="d-flex-between">
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "rgba(0,0,0,0.4)",
                    "&.Mui-checked": {
                      color: "var(--default-background-color) ",
                    },
                  }}
                  name="photos"
                  onChange={handleCheckBoxs}
                  checked={myFilters.photos}
                />
              }
              label="Photos"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "rgba(0,0,0,0.4)",
                    "&.Mui-checked": {
                      color: "var(--default-background-color) ",
                    },
                  }}
                  name="videos"
                  onChange={handleCheckBoxs}
                  checked={myFilters.videos}
                />
              }
              label="Videos"
            />
          </Box>
        </Box>
        <Box className="filter-accordian">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordian("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Property Type</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                className="d-flex"
                columnGap={"15px"}
                flexWrap={"wrap"}
                rowGap={"10px"}
              >
                {user_values?.property_type.map((datas) => (
                  <Typography>
                    <Chip
                      label={datas.text}
                      variant={
                        myFilters.property_type.includes(datas._id)
                          ? "filled"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          myFilters.property_type.includes(datas._id) &&
                          "var( --text-color-1)",
                        color:
                          myFilters.property_type.includes(datas._id) && "#fff",
                      }}
                      onClick={() => handleChange("property_type", datas._id,datas.text)}
                    />
                  </Typography>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleAccordian("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <Typography>Area</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Box mt={2} className="price-slider">
                    <Box className="d-flex-between">
                      <Typography>
                        ₹ {myFilters.areaRangeValue[0]}
                      </Typography>
                      <Typography>
                        ₹ {myFilters.areaRangeValue[1]}
                      </Typography>
                    </Box>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={myFilters.areaRangeValue}
                      onChange={handleAreaChange}
                      // valueLabelDisplay="auto"
                      min={0}
                      max={10000}
                      step={1000}
                      marks={marks_2}
                      // valueLabelFormat={handleSliderValue}
                    />
                  </Box>
                      </AccordionDetails>
                    </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordian("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Posted By</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                className="d-flex"
                columnGap={"15px"}
                flexWrap={"wrap"}
                rowGap={"10px"}
              >
                {user_values?.user_type.map((datas) => (
                  <Typography>
                    <Chip
                      label={datas.text}
                      variant={
                        myFilters.user_type.includes(datas._id)
                          ? "filled"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          myFilters.user_type.includes(datas._id) &&
                          "var( --text-color-1)",
                        color:
                          myFilters.user_type.includes(datas._id) && "#fff",
                      }}
                      onClick={() => handleChange("user_type", datas._id,datas.text)}
                    />
                  </Typography>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordian("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Preferred Plot / Land Type </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                className="d-flex"
                columnGap={"15px"}
                flexWrap={"wrap"}
                rowGap={"10px"}
              >
                {user_values?.kind_of_property.map((datas) => (
                  <Typography>
                    <Chip
                      label={datas.text}
                      variant={
                        myFilters.kind_of_property.includes(datas._id)
                          ? "filled"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          myFilters.kind_of_property.includes(datas._id) &&
                          "var( --text-color-1)",
                        color:
                          myFilters.kind_of_property.includes(datas._id) &&
                          "#fff",
                      }}
                      onClick={() =>
                        handleChange("kind_of_property", datas._id,datas.text)
                      }
                    />
                  </Typography>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleAccordian("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography>Localities</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Select
                labelId="multiple-select-label"
                id="multiple-select"
                multiple
                fullWidth
                className={`${classes.formInputFeild} my-field select-field`}
                value={myFilters.localities}
                // onChange={(e) => handleChange1(e, "localities")}
                renderValue={(selected) =>
                  selected
                    .map((value) =>
                      userLocations?.location?.find(
                        (option) => option === value
                      )
                    )
                    .join(", ")
                }
              >
                {userLocations?.location?.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    className="filters-select"
                    onClick={() => handleLocalities(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleAccordian("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography>Amenities</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Select
                labelId="multiple-select-label"
                id="multiple-select"
                multiple
                fullWidth
                className={`${classes.formInputFeild} my-field select-field`}
                value={myFilters.amenities_val}
                // onChange={(e) => handleChange1(e, "amenities")}
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        user_values?.amenities?.find(
                          (option) => option.text === value
                        ).text
                    )
                    .join(", ")
                }
              >
                {user_values?.amenities?.map((option) => (
                  <MenuItem
                    key={option._id}
                    value={option.text}
                    onClick={() => handleAmenities(option, option._id)}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleAccordian("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Typography>Construction Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                className="d-flex"
                columnGap={"15px"}
                flexWrap={"wrap"}
                rowGap={"10px"}
              >
                {user_values?.availability.map((datas) => (
                  <Typography>
                    <Chip
                      label={datas.text}
                      variant={
                        myFilters.availability?.includes(datas._id)
                          ? "filled"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          myFilters.availability?.includes(datas._id) &&
                          "var( --text-color-1)",
                        color:
                          myFilters.availability?.includes(datas._id) && "#fff",
                      }}
                      onClick={() => handleChange("availability", datas._id,datas.text)}
                    />
                  </Typography>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleAccordian("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8d-content"
              id="panel8d-header"
            >
              <Typography>Furnishing Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                className="d-flex"
                columnGap={"15px"}
                flexWrap={"wrap"}
                rowGap={"10px"}
              >
                {user_values?.furnishing_status?.map((datas) => (
                  <Typography>
                    <Chip
                      label={datas.text}
                      variant={
                        myFilters.furnishing_status.includes(datas._id)
                          ? "filled"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          myFilters.furnishing_status.includes(datas._id) &&
                          "var( --text-color-1)",
                        color:
                          myFilters.furnishing_status.includes(datas._id) &&
                          "#fff",
                      }}
                      onClick={() =>
                        handleChange("furnishing_status", datas._id,datas.text)
                      }
                    />
                  </Typography>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
                      expanded={expanded === "panel9"}
                      onChange={handleAccordian("panel9")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9d-content"
                        id="panel9d-header"
                      >
                        <Typography>BHK Type</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.room_type?.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters?.room_type.includes(
                                    datas._id
                                  )
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.room_type.includes(
                                      datas._id
                                    ) && "var( --text-color-1)",
                                  color:
                                    myFilters.room_type.includes(
                                      datas._id
                                    ) && "#fff",
                                }}
                                onClick={() =>
                                  handleChange("room_type", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel10"}
                      onChange={handleAccordian("panel10")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10d-content"
                        id="panel10d-header"
                      >
                        <Typography>Rera Approved</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          <Typography>
                            Is Rera Approved <Switch checked={myFilters.isRera} onClick={()=>handleSwitchChange("isRera")}  />
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel11"}
                      onChange={handleAccordian("panel11")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel11d-content"
                        id="panel11d-header"
                      >
                        <Typography>Verified Properties</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          <Typography>
                            Is Verified Properties <Switch checked={myFilters.isVerified} onClick={()=>handleSwitchChange("isVerified")}  />
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Container>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <Box
                gap={"15px"}
                display={{ xs: "flex", sm: "flex", md: "none" }}
                flexDirection={"column"}
                alignItems={"start"}
              >
                <Typography
                  onClick={() => setFilterBar(true)}
                  display={{
                    xs: "inline-block",
                    sm: "inline-block",
                    md: "none",
                  }}
                  className={classes.mobile_filter}
                >
                  Filters
                </Typography>
                <Box className="d-flex" >
                <Box
                  className={classes.mobile_search_part}
                  display={{ xs: "block", sm: "block", md: "none" }}
                >
                  <GoogleAutocomplete
                    onLoad={handleLoad}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <TextField
                      inputRef={inputRef}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Search for property "
                      className="mobile-search-field"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ marginLeft: "10px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GoogleAutocomplete>
                </Box>
                <Box ml={2}>
                {/* <Button
                  size="small"
                  disabled={!searchCoordinates}
                  onClick={() => {
                    if (inputValue.length < 1) {
                      alert("Please enter location")
                    } else {
                      localStorage.setItem("search-input",inputValue);
                    navigate(`/search-results/${searchCoordinates?.lat},${searchCoordinates?.lng}`);
                    }
                  }}
                  className={classes.search_btn_small}
                > */}
                  <IoIosSearch 
                  onClick={() => {
                    if (inputValue.length < 1) {
                      alert("Please enter location")
                    } else {
                      localStorage.setItem("search-input",inputValue);
                    navigate(`/search-results/${searchCoordinates?.lat},${searchCoordinates?.lng}`);
                    }
                  }}
                  className={classes.mobile_search_btn_small} />
                {/* </Button> */}
                {/* <Button
                  size="small"
                  onClick={() => {
                    localStorage.removeItem("search-input");
                    if (my_params?.id !== "allproperties") {
                      navigate(`/search-results/allproperties`);
                    }
                    
                  }}
                  className={classes.clear_btn_small}
                > */}
                  <IoMdClose
                  onClick={() => {
                    localStorage.removeItem("search-input");
                    if (my_params?.id !== "allproperties") {
                      navigate(`/search-results/allproperties`);
                    }
                    
                  }}
                  className={classes.mobile_clear_btn_small}
                  />
                {/* </Button> */}
                </Box>
                </Box>
              </Box>
              <Box
                className={classes.filter_part}
                display={{ xs: "none", sm: "none", md: "block" }}
              >
                <Box className="d-flex-between" py={1} px={2}>
                  <Typography fontSize={"20px"}>Filters</Typography>
                  <Button
                    size="small"
                    className={classes.clear_btn}
                    onClick={handleClearAll}
                  >
                    Clear All
                  </Button>
                </Box>
                <hr />
                
                {Array.isArray(previewFilters) &&
                previewFilters.length > 0 &&
                <Box className={classes.filter_preview} >
                {previewFilters.map((el,i)=>(
                  
                <Chip
                  label={el.val}
                  // onClick={handleClick}
                  onDelete={()=>handleDeletePreview(el)}
                  key={i}
                />
               
                ))}

                </Box>
                }
                 
                <Box p={2}>
                  <Typography fontSize={"20px"}>Budget</Typography>
                  <Box mt={2} className="price-slider">
                    <Box className="d-flex-between">
                      <Typography>
                        ₹ {formatNumber(myFilters.rangeValue[0])}
                      </Typography>
                      <Typography>
                        ₹ {formatNumber(myFilters.rangeValue[1])}
                      </Typography>
                    </Box>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={myFilters.rangeValue}
                      onChange={handleRangeChange}
                      // valueLabelDisplay="auto"
                      min={0}
                      max={100000000}
                      step={10000}
                      marks={marks}
                      // valueLabelFormat={handleSliderValue}
                    />
                  </Box>
                  <Box className="d-flex-between">
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "rgba(0,0,0,0.4)",
                              "&.Mui-checked": {
                                color: "var(--default-background-color) ",
                              },
                            }}
                            name="photos"
                            onChange={handleCheckBoxs}
                            checked={myFilters.photos}
                          />
                        }
                        label="Photos"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "rgba(0,0,0,0.4)",
                              "&.Mui-checked": {
                                color: "var(--default-background-color) ",
                              },
                            }}
                            name="videos"
                            onChange={handleCheckBoxs}
                            checked={myFilters.videos}
                          />
                        }
                        label="Videos"
                      />
                    </Box>
                  </Box>
                  <Box className="filter-accordian">
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleAccordian("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Typography>Property Type</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.property_type.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters.property_type.includes(datas._id)
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.property_type.includes(
                                      datas._id
                                    ) && "var( --text-color-1)",
                                  color:
                                    myFilters.property_type.includes(
                                      datas._id
                                    ) && "#fff",
                                }}
                                onClick={() =>
                                  handleChange("property_type", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleAccordian("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <Typography>Area</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Box mt={2} className="price-slider">
                    <Box className="d-flex-between">
                      <Typography>
                        ₹ {myFilters.areaRangeValue[0]}
                      </Typography>
                      <Typography>
                        ₹ {myFilters.areaRangeValue[1]}
                      </Typography>
                    </Box>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={myFilters.areaRangeValue}
                      onChange={handleAreaChange}
                      // valueLabelDisplay="auto"
                      min={0}
                      max={10000}
                      step={1000}
                      marks={marks_2}
                      // valueLabelFormat={handleSliderValue}
                    />
                  </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleAccordian("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                      >
                        <Typography>Posted By</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.user_type.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters.user_type.includes(datas._id)
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.user_type.includes(datas._id) &&
                                    "var( --text-color-1)",
                                  color:
                                    myFilters.user_type.includes(datas._id) &&
                                    "#fff",
                                }}
                                onClick={() =>
                                  handleChange("user_type", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleAccordian("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                      >
                        <Typography>Preferred Plot / Land Type </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.kind_of_property.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters.kind_of_property.includes(datas._id)
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.kind_of_property.includes(
                                      datas._id
                                    ) && "var( --text-color-1)",
                                  color:
                                    myFilters.kind_of_property.includes(
                                      datas._id
                                    ) && "#fff",
                                }}
                                onClick={() =>
                                  handleChange("kind_of_property", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleAccordian("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                      >
                        <Typography>Localities</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <FormControl sx={{width:"100%"}} className={myFilters.localities.length === 0 ? classes.multi_select :  classes.multi_select_val}>
                      <InputLabel id="demo-multiple-name-label">Select Localities</InputLabel>
                        <Select
                          labelId="multiple-select-label"
                          id="multiple-select"
                          multiple
                          fullWidth
                          className={`${classes.formInputFeild} my-field select-field`}
                          value={myFilters.localities}
                          // onChange={(e) => handleChange1(e, "localities")}
                          renderValue={(selected) =>
                            selected
                              .map((value) =>
                                userLocations?.location?.find(
                                  (option) => option === value
                                )
                              )
                              .join(", ")
                          }
                        >
                          {userLocations?.location?.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              className="filters-select"
                              onClick={() => handleLocalities(option)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel6"}
                      onChange={handleAccordian("panel6")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                      >
                        <Typography>Amenities</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <FormControl sx={{width:"100%"}} className={myFilters.amenities_val.length === 0 ? classes.multi_select :  classes.multi_select_val}>
                      <InputLabel id="demo-multiple-name-label">Select Amenities</InputLabel>
                        <Select
                          labelId="multiple-select-label demo-multiple-name-label"
                          id="multiple-select"
                          multiple
                          fullWidth
                          className={`${classes.formInputFeild} my-field select-field`}
                          value={myFilters.amenities_val}
                          // onChange={(e) => handleChange1(e, "amenities")}
                          renderValue={(selected) =>
                            selected
                              .map(
                                (value) =>
                                  user_values?.amenities?.find(
                                    (option) => option.text === value
                                  ).text
                              )
                              .join(", ")
                          }

                          // renderValue={(selected) => {
                          //   if (selected.length === 0) {
                          //     return <em>Placeholder</em>;
                          //   }
                
                          //   return selected.join(', ');
                          // }}
                        >
                           
                          {user_values?.amenities?.map((option) => (
                            <MenuItem
                              key={option._id}
                              value={option.text}
                              onClick={() =>
                                handleAmenities(option, option._id)
                              }
                            >
                              {option.text}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel7"}
                      onChange={handleAccordian("panel7")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7d-content"
                        id="panel7d-header"
                      >
                        <Typography>Construction Status</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.availability?.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters.availability?.includes(datas._id)
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.availability?.includes(
                                      datas._id
                                    ) && "var( --text-color-1)",
                                  color:
                                    myFilters.availability?.includes(
                                      datas._id
                                    ) && "#fff",
                                }}
                                onClick={() =>
                                  handleChange("availability", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel8"}
                      onChange={handleAccordian("panel8")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8d-content"
                        id="panel8d-header"
                      >
                        <Typography>Furnishing Status</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.furnishing_status?.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters.furnishing_status.includes(
                                    datas._id
                                  )
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.furnishing_status.includes(
                                      datas._id
                                    ) && "var( --text-color-1)",
                                  color:
                                    myFilters.furnishing_status.includes(
                                      datas._id
                                    ) && "#fff",
                                }}
                                onClick={() =>
                                  handleChange("furnishing_status", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel9"}
                      onChange={handleAccordian("panel9")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9d-content"
                        id="panel9d-header"
                      >
                        <Typography>BHK Type</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          {user_values?.room_type?.map((datas) => (
                            <Typography>
                              <Chip
                                label={datas.text}
                                variant={
                                  myFilters?.room_type.includes(
                                    datas._id
                                  )
                                    ? "filled"
                                    : "outlined"
                                }
                                sx={{
                                  backgroundColor:
                                    myFilters.room_type.includes(
                                      datas._id
                                    ) && "var( --text-color-1)",
                                  color:
                                    myFilters.room_type.includes(
                                      datas._id
                                    ) && "#fff",
                                }}
                                onClick={() =>
                                  handleChange("room_type", datas._id,datas.text)
                                }
                              />
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel10"}
                      onChange={handleAccordian("panel10")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10d-content"
                        id="panel10d-header"
                      >
                        <Typography>Rera Approved</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          <Typography>
                            Is Rera Approved <Switch checked={myFilters.isRera} onClick={()=>handleSwitchChange("isRera")}  />
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel11"}
                      onChange={handleAccordian("panel11")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel11d-content"
                        id="panel11d-header"
                      >
                        <Typography>Verified Properties</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="d-flex"
                          columnGap={"15px"}
                          flexWrap={"wrap"}
                          rowGap={"10px"}
                        >
                          <Typography>
                            Is Verified Properties <Switch checked={myFilters.isVerified} onClick={()=>handleSwitchChange("isVerified")}  />
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Box className={classes.search_row_one}>
                <Box className="d-flex-between" flexWrap={"wrap"}>
                  <Typography>
                  <span onClick={()=>navigate("/homepage")} className={classes.single_breadcrums_active}>Home</span>/
                  <span className={classes.single_breadcrums_inactive}>{ localStorage.getItem("search-input")?? _clicked ?? "All Properties"}</span>
                  </Typography>
                  <Box className="d-flex" gap={"10px"}>
                    <Typography>Sort By: </Typography>
                    <TextField
                      select
                      value={selectedSort}
                      className="manage-listing"
                    >
                      <MenuItem value="relevance" 
                      onClick={() => {
                        setSelectedSort("relevance");
                        setMyFilters({
                          ...myFilters,
                          price: "",
                          sqft: "",
                          listing: "createdAt-asc",
                        });
                      }}
                      >Relevance</MenuItem>
                      <MenuItem
                        value="price-high"
                        onClick={() => {
                          setSelectedSort("price-high");
                          setMyFilters({
                            ...myFilters,
                            listing: "price.expected_price-dsc",
                          });
                        }}
                      >
                        Price - High to Low
                      </MenuItem>
                      <MenuItem
                        value="price-low"
                        onClick={() => {
                          setSelectedSort("price-low");
                          setMyFilters({
                            ...myFilters,
                            listing: "price.expected_price-asc",
                          });
                        }}
                      >
                        Price - Low to High
                      </MenuItem>
                      <MenuItem
                        value="most-recent"
                        onClick={() => {
                          setSelectedSort("most-recent");
                          setMyFilters({
                            ...myFilters,
                            price: "",
                            sqft: "",
                            listing: "createdAt-dsc",
                          });
                        }}
                      >
                        Most Recent
                      </MenuItem>
                      <MenuItem
                        value="sqft-low"
                        onClick={() => {
                          setSelectedSort("sqft-low");
                          setMyFilters({
                            ...myFilters,
                            listing: "rate_per_sqft-asc",
                          });
                        }}
                      >
                        Rate/Sqft - Low to High
                      </MenuItem>
                      <MenuItem
                        value="sqft-high"
                        onClick={() => {
                          setSelectedSort("sqft-high");
                          setMyFilters({
                            ...myFilters,
                            listing: "rate_per_sqft-dsc",
                          });
                        }}
                      >
                        Rate/Sqft - High to Low
                      </MenuItem>
                    </TextField>
                  </Box>
                </Box>
                <Typography className={classes.result_counts}>
                  {newAllProperties?.TotalCount} properties found
                </Typography>
              </Box>
              <Box>
                {isLoading ? (
                  <Box
                    display={"flex"}
                    height={"350px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Loader />
                  </Box>
                ) : Array.isArray(newAllProperties?.properties) &&
                  newAllProperties?.properties.length > 0 ? (
                  newAllProperties?.properties.map((datas) => (
                    <Box
                      my={3}
                      boxShadow={"0 0 7px rgba(0,0,0,0.3)"}
                      borderRadius={"8px"}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4}>
                          <img
                            src={
                              datas.photos[0]?.image
                                ? handleSelectCover(datas.photos)
                                : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                            }
                            alt="image"
                            width={"100%"}
                            height={"268px"}
                            style={{ objectFit: "contain" }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                          <Box p={2}>
                            <Box className="d-flex-between">
                              <Box>
                                <Typography
                                  fontWeight={"600"}
                                  fontSize={"21px"}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    dispatch({
                                      type: FILTER_DETAILS,
                                      payload: myFilters,
                                    });
                                    navigate(`/singleproperty/${datas._id}`);
                                  }}
                                >
                                  {datas.created_user?.name}
                                </Typography>
                                <span>Plot for sale in {datas?.location}</span>
                              </Box>
                              <Typography
                                className={
                                  datas.likedByUser
                                    ? `${classes.active_heart} ${classes.heart_icon}`
                                    : classes.heart_icon
                                }
                                onClick={() => handleWishlist(datas._id)}
                              >
                                <HeartIcon />
                              </Typography>
                            </Box>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-around"}
                              mt={1}
                            >
                              <Box>
                                <Typography
                                  fontWeight={"600"}
                                  fontSize={"18px"}
                                >
                                  ₹{" "}
                                  {datas.price?.expected_price
                                    ? formatNumber(datas.price?.expected_price)
                                    : "n/a"}
                                </Typography>
                                <span>Price (Negotiable)</span>
                              </Box>
                              <Typography
                                width={"1px"}
                                height={"33px"}
                                bgcolor={"rgba(0,0,0,0.4)"}
                              ></Typography>
                              <Box>
                                <Typography
                                  fontWeight={"600"}
                                  fontSize={"18px"}
                                >
                                  ₹ {datas.rate_per_sqft?.toFixed(2)}
                                </Typography>
                                <span>Per Sqft</span>
                              </Box>
                              <Typography
                                width={"1px"}
                                height={"33px"}
                                bgcolor={"rgba(0,0,0,0.4)"}
                              ></Typography>
                              <Box>
                                <Typography
                                  fontWeight={"600"}
                                  fontSize={"18px"}
                                >
                                  {datas.area_of_property} Sqft
                                </Typography>
                                <span>Plot Area</span>
                              </Box>
                            </Box>
                            <Typography
                              className={classes.single_property_highlights}
                            >
                              Highlights :
                              {datas.other_features.slice(0, 2).map((el) => (
                                <Chip label={el} />
                              ))}
                              <span
                                className={classes.single_property_highlights}
                              >
                                {datas.other_features.length - 2 > 0
                                  ? `+${datas.other_features.length - 2}`
                                  : ""}
                              </span>
                            </Typography>
                            <span>
                              {datas.property_details?.length > 150
                                ? datas.property_details
                                    ?.slice(0, 150)
                                    .concat(" ...")
                                : datas.property_details}
                            </span>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"15px"}
                              justifyContent={"end"}
                              mt={1}
                            >
                              <Button 
                              className={classes.search_res_btn}
                              // href= {`https://wa.me/+91${datas?.created_user?.phone}?text=${prefilledMessage}`}
                              // url="https://web.whatsapp.com/"
                              // target="_blank"
                              onClick={()=>handleWhatsAppClicked(datas?.created_user)}
                              >
                                <WhatsappIcon /> WhatsApp
                              </Button>
                              <Button className={classes.search_res_btn}>
                                <ChatIcon /> SMS
                              </Button>
                              <Button
                                className={classes.search_res_btn}
                                onClick={() => {
                                  setContactPopupDetails({
                                    phone: datas?.created_user?.phone,
                                    email: datas?.created_user?.email,
                                    name: datas?.created_user?.name,
                                  });
                                  setContactPopup(true);
                                }}
                              >
                                <ContactIcon /> Call
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))
                 
                ) : (
                  <Box className={classes.no_data}>
                    <img
                      src={
                        "https://static.vecteezy.com/system/resources/thumbnails/009/007/126/small/document-file-not-found-search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
                      }
                      alt="no-data-found"
                    />
                    <span>Oops, No Properties found</span>
                  </Box>
                )}
                 {!isLoading && newAllProperties?.properties?.length > 0 && (
                    <Box display={"flex"} justifyContent={"end"}>
                      <Pagination
                        count={Math.ceil(newAllProperties?.TotalCount / 10)}
                        page={myFilters.page}
                        onChange={handlePageChange}
                      />
                    </Box>
                  )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box className={classes.ad_part}>
                <img src={AdImg} width={"100%"}/>
                <img src={AdImg} width={"100%"}/>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ContactPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
        />
        {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
      </Container>
      <Drawer
        open={filterBar}
        onClose={() => setFilterBar(false)}
        anchor={"bottom"}
      >
        {DrawerList}
      </Drawer>
      {loginErr && (
          <AlertBox type={"error"} message={loginErr} stateName={()=>setLoginErr("")} />
        )}
    </>
  );
}

export default SearchResults;
