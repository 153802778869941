import React, { useState, useEffect } from "react";
import classes from "./auth.module.css";
import { Button, Card, Typography } from "@mui/material";
import DefaultLayout from "./defaultLayout";
import { useNavigate } from "react-router";
import { CloseIcon, LockICon } from "../../Assets/Icons/icons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { Alphabetic } from "../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { enterPersonalInfo, pageLoader, userRegister } from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";

const PersonalDetails = () => {
  const PersonalDetailsDiv = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [focusedElement, setFocusedElement] = useState("");
    const { ln, error } = useSelector((state) => state.userLogin);
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    const country_code = localStorage.getItem("countryCode");

    const handleFocus = (name) => {
      setFocusedElement(name);
    };

    const handleBlur = () => {
      setFocusedElement("");
    };

    const handleSubmit = () => {
      let registerInfos = JSON.parse(sessionStorage.getItem("registerInfos"));

      let obj = {
        name,
        email,
        location: location ? location : "",
      };

      sessionStorage.setItem("registerInfos",JSON.stringify({...registerInfos,...obj}));

      if (registerInfos?.countryCode == 91) {
        const userEntry = {
          name: name,
          email: email,
          location: location ? location : "",
        };
        // dispatch(pageLoader(true));
        
        dispatch(
          enterPersonalInfo(setErrorAlert, userEntry, userToken?.id, navigate)
        );
        dispatch(pageLoader(false));
      } else {
        const userEntry = {
          phone: registerInfos?.phone,
          country_code:registerInfos?.countryCode
        };
        dispatch(pageLoader(true));
        dispatch(userRegister(setErrorAlert, userEntry, navigate));
        dispatch(pageLoader(false));
      }

    };
    const closeAlert = () => {
      setErrorAlert(false);
    };

    return (
      <Card className={classes.registerCard}>
        <Typography variant="h5" className={classes.registerHeader}>
          {ln && ln["add_more_details"]
            ? ln["add_more_details"]
            : "Add More Details"}
        </Typography>
        <Button
          className={classes.closeButton}
          variant="text"
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </Button>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={classes.SignUpForm}
        >
          <StyledInput
            label={ln && ln["full_name"] ? ln["full_name"] : "Full Name"}
            variant="standard"
            fullWidth
            id="nameInput"
            placeholder=""
            className={`mb-4 ${classes.formInputFeild} ${
              focusedElement === "nameInput" && classes.FocusedClass
            }`}
            value={name}
            size="normal"
            onChange={(e) => setName(e.target.value)}
            onFocus={() => handleFocus("nameInput")}
            onBlur={handleBlur}
            onKeyPress={(e) => {
              Alphabetic(e);
            }}
            validators={["required"]} // Add this line
            errorMessages={["Name is required"]}
          />
          <StyledInput
            label={ln && ln["email_id"] ? ln["email_id"] : "Email Id"}
            variant="standard"
            fullWidth
            id="emailInput"
            placeholder=""
            className={`mb-4 ${classes.formInputFeild} ${
              focusedElement === "emailInput" && classes.FocusedClass
            }`}
            value={email}
            size="normal"
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => handleFocus("emailInput")}
            onBlur={handleBlur}
            validators={ country_code !== "91" && ["required", "isEmail"]} 
            errorMessages={country_code !== "91" && ["Email ID is required", "Enter a valid Email ID"]}
          />
          <StyledInput
            label={
              ln && ln["location"]
                ? `${ln["location"]}(${ln["optional"]})`
                : "Location(Optional)"
            }
            variant="standard"
            fullWidth
            id="locationInput"
            placeholder=""
            className={`mb-4 ${classes.formInputFeild} ${
              focusedElement === "locationInput" && classes.FocusedClass
            }`}
            value={location}
            size="normal"
            onChange={(e) => setLocation(e.target.value)}
            onFocus={() => handleFocus("locationInput")}
            onBlur={handleBlur}
          />
          <Typography variant="p" className={classes.LockTermsText}>
            <LockICon />
            <span>Your information is protected by our privacy policy</span>
          </Typography>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            className={`mt-4 ${classes.continueBtn}`}
          >
            {ln && ln["register"] ? ln["register"] : "Register"}
          </Button>
        </ValidatorForm>
        {errorAlert && error && (
          <AlertBox type={"error"} message={error} stateName={closeAlert} />
        )}
      </Card>
    );
  };

  return <DefaultLayout children={<PersonalDetailsDiv />} />;
};

export default PersonalDetails;
