import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Radio,
  Tabs,
  Tab,
  Popover,
  Checkbox,
  Pagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./property.css";
import BuildingOne from "../../Assets/Images/building-1.png";
import classes from "./property.module.css";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import DeleteConfirmation from "../popup/DeleteConfirmation";
import { useDispatch, useSelector } from "react-redux";
import {
  allUserProperties,
  clearPropertyData,
  deleteProperty,
  getUserContactOwner,
  singleProperty,
  updateProperty,
  updatePropertyStatus,
} from "../../Redux/Actions/userActions";
import { useNavigate } from "react-router-dom";
import { ChatIcon, ContactIcon, SortingIcon, WhatsappIcon } from "../../Assets/Icons/icons";
import {
  Convertdate,
  countDaysFromDateToNow,
  formatNumber,
} from "../../Utilities/commonFunctions";
import ContactPop from "../popup/ContactPop";
import Loader from "../popup/Loader";
import ContactOwnerPop from "../popup/ContactOwnerPop";

let dummy = [0, 1, 2, 3];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PropertyListing() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [deletePop, setDeletePop] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [myProperties, setMyProperties] = useState({
    properties: [],
    TotalCount: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [myResponses, setMyResponses] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [sorting, setSortings] = useState({ listing: "asc", page: 1 });
  const [contactPopup, setContactPopup] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [propertyFilter, setPropertyFilter] = useState("-1");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let user_id = JSON.parse(localStorage.getItem("userToken"))?.id;

  const handlePageChange = (event, value) => {
    setSortings({ ...sorting, page: value });
  };

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDeletePop(false);
  };

  function handleAllResponses() {
    dispatch(getUserContactOwner(_token, user_id, setMyResponses));
  }

  function handleAllProperties() {
    setIsLoading(true);
    dispatch(
      allUserProperties(
        _token,
        setMyProperties,
        user_id,
        sorting,
        propertyFilter
      )
    );
    setIsLoading(false);
  }

  function handleDeleteProperty() {
    // dispatch(
    //   deleteProperty(_token, selectedProperty?._id, setDeletePop, setDeleteFlag)
    // );

    let body = {
      property_status:
        selectedProperty?.property_status === "Active"
          ? "Deactivated"
          : "Active",
    };

    dispatch(
      updatePropertyStatus(
        _token,
        selectedProperty?._id,
        setDeletePop,
        setDeleteFlag,
        body
      )
    );
  }

  function handleSorting(e) {
    const { checked } = e.target;
    if (checked) {
      setSortings({ ...sorting, listing: "dsc" });
    } else {
      setSortings({ ...sorting, listing: "asc" });
    }
  }

  function handleSelectCover(photos) {
    let property_photos = photos;
    let cover_img = "";

    property_photos.forEach((val) => {
      if (val?.is_cover_image) {
        cover_img = val?.image;
      } else {
        if (cover_img === "") {
          cover_img = val?.image;
        }
      }
    });

    return `${process.env.REACT_APP_API_URL}/${cover_img}`;
  }

  const handleGetContact = (e) => {
    const { name, value } = e.target;
    setGetContact({ ...getContact, [name]: value });
  };

  useEffect(() => {
    handleAllProperties();
    handleAllResponses();
    sessionStorage.clear();
    localStorage.removeItem("error");
    dispatch(clearPropertyData(null));
  }, []);

  useEffect(() => {
    handleAllProperties();
  }, [sorting, propertyFilter]);

  useEffect(() => {
    if (deleteFlag) {
      setDeleteFlag(false);
      handleAllProperties();
    }
  }, [deleteFlag]);

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({
        ...getContact,
        name: Con_details?.name,
        phone: Con_details?.phone,
        email: Con_details?.email,
      });
    }
  }, []);
  return (
    <>
      <Container>
        <Box className="all-listings">
          {value === 0 && (
            <Box
              className="d-flex-between"
              gap={"15px"}
              mb={2}
              display={{ xs: "flex", sm: "none", md: "none" }}
            >
              <Box>
                <TextField
                  select
                  value={propertyFilter}
                  className="manage-listing"
                  onChange={(e) => setPropertyFilter(e.target.value)}
                  sx={{ minWidth: "130px" }}
                >
                  <MenuItem
                    value="-1"
                    // onClick={() => navigate(`/singleproperty/${datas._id}`)}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value="Active"
                    // onClick={() => {
                    //   dispatch(
                    //     singleProperty(
                    //       _token,
                    //       datas._id,
                    //       setPropertyDetails,
                    //       navigate,
                    //       "edit"
                    //     )
                    //   );
                    // }}
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    value="Pending_Approval"
                    // onClick={() => {
                    //   setSelectedProperty(datas);
                    //   setDeletePop(true);
                    // }}
                  >
                    Pending Approval
                  </MenuItem>
                  <MenuItem value="Deactivated">Deactivated</MenuItem>
                  <MenuItem value="Drafted">Drafts</MenuItem>
                </TextField>
              </Box>
              <Button onClick={handleClick} className={classes.sort_btn}>
                <SortingIcon /> Sort
              </Button>
            </Box>
          )}
          <Box className="d-flex-between">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={`All Listings (${myProperties?.TotalCount})`} {...a11yProps(0)} />
              <Tab label=" All Responses" {...a11yProps(1)} />
            </Tabs>
            {value === 0 && (
              <Box
                alignItems={"center"}
                gap={"15px"}
                display={{ xs: "none", sm: "flex", md: "flex" }}
              >
                <Box>
                  <TextField
                    select
                    value={propertyFilter}
                    className="manage-listing"
                    onChange={(e) => setPropertyFilter(e.target.value)}
                    sx={{ minWidth: "130px" }}
                  >
                    <MenuItem value="-1">All</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Pending_Approval">
                      Pending Approval
                    </MenuItem>
                    <MenuItem value="Deactivated">Deactivated</MenuItem>
                    <MenuItem value="Drafted">Drafts</MenuItem>
                  </TextField>
                </Box>
                <Button onClick={handleClick} className={classes.sort_btn}>
                  <SortingIcon /> Sort
                </Button>
              </Box>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopperClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className="sorting-popover"
            >
              <Typography sx={{ px: 2, pt: 2 }}>
                <b>Sort Listing by</b>
              </Typography>
              <Typography sx={{ px: 2, py: 1, cursor: "pointer" }}>
                <Checkbox
                  checked={sorting.listing === "dsc"}
                  onChange={(e) => handleSorting(e)}
                />{" "}
                Newest
              </Typography>
            </Popover>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <>
              {isLoading ? (
                <Box
                  display={"flex"}
                  height={"350px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Loader />
                </Box>
              ) : myProperties?.properties.length > 0 ? (
                myProperties?.properties.map((datas, i) => {
                  return (
                    <Box
                      boxShadow={"0 0 10px rgba(0, 0, 0, 0.2)"}
                      borderRadius={"12px"}
                      mb={3}
                      key={i}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                          <img
                            src={
                              datas.photos[0]?.image
                                ? handleSelectCover(datas.photos)
                                : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                            }
                            alt="image"
                            width={"100%"}
                            height={"200px"}
                            style={{ objectFit: "contain", minHeight: "200px" }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <Box className={classes.single_property}>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Box
                                className={classes.property_header}
                                width={{ xs: "56%", sm: "70%", md: "100%" }}
                              >
                                <Typography
                                // sx={{ cursor: "pointer" }}
                                // onClick={() =>
                                //   navigate(`/singleproperty/${datas._id}`)
                                // }
                                >
                                  {datas.offer_type} {datas.property_type}{" "}
                                  {datas.kind_of_property}{" "}
                                  {datas.property_status === "Drafted" ? (
                                    <span className={classes.draft_list}>
                                      Draft
                                    </span>
                                  ) : datas.property_status ===
                                    "Pending_Approval" ? (
                                    <span className={classes.pending_list}>
                                      Pending
                                    </span>
                                  ) : datas.property_status === "Active" ? (
                                    <span className={classes.active_list}>
                                      Active
                                    </span>
                                  ) : (
                                    datas.property_status === "Deactivated" && (
                                      <span className={classes.deactive_list}>
                                        Deactivated
                                      </span>
                                    )
                                  )}
                                </Typography>
                                <span>
                                  {datas.location || "n/a"},{" "}
                                  {datas.city || "n/a"}
                                </span>
                              </Box>
                              {/* <Box className="d-flex" mt={4}>
                                <MdEdit
                                  className={classes.edit_icon}
                                  onClick={() => {
                                    dispatch(
                                      singleProperty(
                                        _token,
                                        datas._id,
                                        setPropertyDetails,
                                        navigate,
                                        "edit"
                                      )
                                    );
                                  }}
                                />
                                <MdDelete
                                  className={classes.del_icon}
                                  onClick={() => {
                                    setSelectedProperty(datas);
                                    setDeletePop(true);
                                  }}
                                />
                              </Box> */}
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              flexWrap={"wrap"}
                              mt={{ xs: 3 }}
                            >
                              <Box
                                display={"flex"}
                                gap={{ xs: "25px", sm: "47px", md: "70px" }}
                              >
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Price</small>
                                  <span>
                                    ₹
                                    {datas.price?.expected_price
                                      ? formatNumber(
                                          datas.price?.expected_price
                                        )
                                      : " n/a"}
                                  </span>
                                </Box>
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Property ID</small>
                                  <span>{datas._id.slice(0, 7)}</span>
                                </Box>
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Duration</small>
                                  <span>
                                    Posted{" "}
                                    {countDaysFromDateToNow(datas.createdAt)}{" "}
                                    days ago
                                  </span>
                                </Box>
                              </Box>
                              <Box mt={{ xs: 3, sm: 0, md: 0 }}>
                                <TextField
                                  select
                                  value={"Manage Listing"}
                                  className="manage-listing"
                                >
                                  <MenuItem
                                    value="Preview"
                                    onClick={() =>
                                      navigate(`/singleproperty/${datas._id}`)
                                    }
                                  >
                                    Preview
                                  </MenuItem>
                                  <MenuItem
                                    value="Add/Edit Details"
                                    onClick={() => {
                                      dispatch(
                                        singleProperty(
                                          _token,
                                          datas._id,
                                          setPropertyDetails,
                                          navigate,
                                          "edit"
                                        )
                                      );
                                    }}
                                  >
                                    Add/Edit Details
                                  </MenuItem>
                                  <MenuItem
                                    value="Deactivate"
                                    onClick={() => {
                                      setSelectedProperty(datas);
                                      setDeletePop(true);
                                    }}
                                    hidden={
                                      datas.property_status !== "Active" &&
                                      datas.property_status !== "Deactivated"
                                    }
                                  >
                                    {datas.property_status === "Active"
                                      ? "Deactivate"
                                      : "Activate"}
                                  </MenuItem>
                                  <MenuItem value="Upgrade">Upgrade</MenuItem>
                                  <MenuItem value="Manage Listing" hidden>
                                    Manage Listing
                                  </MenuItem>
                                </TextField>
                              </Box>
                            </Box>
                            <span className={classes.plan_type}>Free Plan</span>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Box className={classes.no_data}>
                  <img
                    src={
                      "https://static.vecteezy.com/system/resources/thumbnails/009/007/126/small/document-file-not-found-search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
                    }
                    alt="no-data-found"
                  />
                  <span>Oops, No Properties found</span>
                  {/* <span onClick={()=>navigate("/postproperty")} >
              Click to add your first property
              </span> */}
                </Box>
              )}
              {myProperties?.properties?.length > 0 && (
                <Box display={"flex"} justifyContent={"end"}>
                  <Pagination
                    count={Math.ceil(myProperties?.TotalCount / 10)}
                    page={sorting.page}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <>
              {myResponses.length > 0 ? (
                myResponses?.map((datas, i) => {
                  return (
                    <Box
                      boxShadow={"0 0 10px rgba(0, 0, 0, 0.2)"}
                      borderRadius={"12px"}
                      mb={3}
                      key={i}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className={classes.single_property}>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Box className={classes.property_header}>
                                <Typography
                                // sx={{ cursor: "pointer" }}
                                // onClick={() =>
                                //   navigate(`/singleproperty/${datas._id}`)
                                // }
                                >
                                  {datas.property?.offer_type}{" "}
                                  {datas.property?.property_type}{" "}
                                  {datas.property?.kind_of_property}{" "}
                                  {datas.property?.property_status ===
                                    "Drafted" && (
                                    <span className={classes.draft_list}>
                                      Draft
                                    </span>
                                  )}
                                </Typography>
                                <span>{datas.property?.location || "n/a"}</span>
                              </Box>
                              <Box>
                                {Convertdate(datas.property?.createdAt)}
                              </Box>
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              flexWrap={"wrap"}
                              mt={5}
                            >
                              <Box
                                display={"flex"}
                                gap={{ xs: "25px", sm: "47px", md: "70px" }}
                              >
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Name</small>
                                  <span>
                                  {datas?.name}
                                  </span>
                                </Box>
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Phone</small>
                                  <span>{datas?.phone}</span>
                                </Box>
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Email</small>
                                  <span>
                                  {datas?.email}
                                  </span>
                                </Box>
                              </Box>
                              </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              flexWrap={"wrap"}
                              mt={5}
                            >
                              <Box
                                display={"flex"}
                                gap={{ xs: "25px", sm: "47px", md: "70px" }}
                              >
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Price</small>
                                  <span>
                                    ₹
                                    {datas.property?.price?.expected_price
                                      ? formatNumber(
                                          datas.property?.price?.expected_price
                                        )
                                      : " n/a"}
                                  </span>
                                </Box>
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Property ID</small>
                                  <span>{datas.property?._id.slice(0, 7)}</span>
                                </Box>
                                <Box
                                  className={classes.single_property_details}
                                >
                                  <small>Duration</small>
                                  <span>
                                    Posted{" "}
                                    {countDaysFromDateToNow(
                                      datas.property?.createdAt
                                    )}{" "}
                                    days ago
                                  </span>
                                </Box>
                              </Box>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={"15px"}
                                mt={1}
                              >
                                <Button
                                  className={classes.search_res_btn}
                                  href= {`https://wa.me/+91${datas?.phone}`}
                                  url="https://web.whatsapp.com/"
                                  target="_blank"
                                >
                                  <WhatsappIcon /> WhatsApp
                                </Button>
                                <Button className={classes.search_res_btn}>
                                  <ChatIcon /> SMS
                                </Button>
                                <Button
                                  className={classes.search_res_btn}
                                  onClick={() => {
                                    setContactPopupDetails({
                                      phone: datas?.phone,
                                      email: datas?.email,
                                      name: datas?.name,
                                    });
                                    setContactPopup(true);
                                  }}
                                >
                                  <ContactIcon /> Call
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Box className={classes.no_data}>
                  <img
                    src={
                      "https://static.vecteezy.com/system/resources/thumbnails/009/007/126/small/document-file-not-found-search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
                    }
                    alt="no-data-found"
                  />
                  <span>Currently, No Responses received yet !</span>
                </Box>
              )}
            </>
          </CustomTabPanel>
        </Box>
        <DeleteConfirmation
          open={deletePop}
          handleClose={handleClose}
          handleDeleteProperty={handleDeleteProperty}
          selectedProperty={selectedProperty}
        />
        {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
        <ContactPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          // handleGetContact={handleGetContact}
          // getContact={getContact}
        />
      </Container>
    </>
  );
}

export default PropertyListing;
