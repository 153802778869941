import { errorAlert } from "../Actions/userActions";
import { EMPTY_PROPERTY_DATA, ERROR_ALERT, GET_LANGUAGE_DATA_SUCCESS, OTP_VERIFICATION_REQUEST_FAIL, OTP_VERIFICATION_REQUEST_SUCCESS, PAGE_LOADER, PROPERTY_DATA, RESEND_OTP_VALUE, SUCCESS_ALERT, USER_LOGIN_REQUEST_FAIL, USER_LOGIN_REQUEST_SUCCESS, USER_REGISTER_REQUEST_FAIL, USER_REGISTER_REQUEST_SUCCESS,ALL_PROPERTIES, CLEAR_PROPERTY_DATA, FILTER_DETAILS, SERVICES_LIST } from "../Constants/userConstants";

const initialState = {
    loading: false,
    userInfo: null,
    error: null,
    success: null,
    headerName: "dashboard",
    toggleSideBar: false,
    otp_data: null,
    ln: null,
    propertyData:{},
    errorAlert:null,
    successAlert:null,
    allProperties:null,
    filterDetails:null,
    servicesList:null,
}

export const userReducers = (state = initialState, action) => {
    switch (action.type) {
        case PAGE_LOADER:
            return { ...state, loading: action.payload };
        case USER_LOGIN_REQUEST_SUCCESS:
            return { ...state, userInfo: action.payload, error: null };
        case USER_LOGIN_REQUEST_FAIL:
            return { ...state, error: action.payload };
        case USER_REGISTER_REQUEST_SUCCESS:
            return { ...state, success: action.payload, error: null };
        case USER_REGISTER_REQUEST_FAIL:
            return { ...state, error: action.payload };
        case OTP_VERIFICATION_REQUEST_SUCCESS:
            return { ...state, userInfo: action.payload, error: null };
        case OTP_VERIFICATION_REQUEST_FAIL:
            return { ...state, error: action.payload };
        case RESEND_OTP_VALUE:
            return { ...state, otp_data: action.payload, error: null };
        case GET_LANGUAGE_DATA_SUCCESS:
            return { ...state, ln: action.payload, error: null };
        case PROPERTY_DATA:
            return { ...state, propertyData: {...state.propertyData,...action.payload}, error: null };
        case EMPTY_PROPERTY_DATA:
            return { ...state, propertyData: {}, error: null };
        case CLEAR_PROPERTY_DATA:
            return { ...state, propertyData: action.payload, error: null };
        case ERROR_ALERT:
            return { ...state, errorAlert: action.payload, error: null };
        case SUCCESS_ALERT:
            return { ...state, successAlert: action.payload, error: null };
        case ALL_PROPERTIES:
            return { ...state, allProperties: action.payload, error: null };
        case FILTER_DETAILS:
            return { ...state, filterDetails: action.payload, error: null };
        case SERVICES_LIST:
            return { ...state, servicesList: action.payload, error: null };
        default:
            
            return state;
    }
}