import React, { useState, useEffect } from "react";
import classes from "./auth.module.css";
import { Button, Card, Typography } from "@mui/material";
import DefaultLayout from "./defaultLayout";
import { CloseIcon, WhatsappIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router";
import OtpInput from "react-otp-input";
import AlertBox from "../../Components/AlertBox";
import {
  pageLoader,
  resendOtp,
  verifyLoginOtp,
  verifyOtp,
} from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const OtpVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [defaultError, setDefaultError] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const { otp_data, ln } = useSelector((state) => state.userLogin);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const OtpVerificationDiv = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [disable, setDisable] = useState(false);
    const [time, setTime] = useState(60);

    const handleResendOtp = () => {
      const userEntry = {
        username: otp_data.phone,
      };
      dispatch(pageLoader(true));
      dispatch(resendOtp(setErrorAlert, userEntry, setTime));
    };

    useEffect(() => {
      const timer = setInterval(() => {
        setTime((prevCount) => prevCount - 1);
        setDisable(true);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }, []);

    useEffect(() => {
      if (time === 0) {
        clearInterval(time);
        setDisable(false);
      }
    }, [time]);

    const handleSubmit = () => {
      let registerInfos = JSON.parse(sessionStorage.getItem("registerInfos"));
      if (otp.length === 6) {
        const userEntry = {
          username: otp_data?.phone,
          otp: otp,
        };
        dispatch(pageLoader(true));
        if (window.location.pathname === "/register-otp-verification") {

          let my_url = "";
          if (registerInfos?.countryCode == 91) {
            my_url = "/personal-details";
          } else {
            my_url = "/homepage";
          }
          dispatch(
            verifyOtp(setErrorAlert, userEntry, navigate, my_url)
          );
        } else {
          dispatch(verifyLoginOtp(setErrorAlert, userEntry, navigate));
        }
        dispatch(pageLoader(false));
      } else {
        setDefaultError("Please enter valid OTP");
        setErrorAlert(true);
      }
    };

    return (
      <Card className={classes.registerCard}>
        <Typography variant="h5" className={classes.registerHeader}>
          {ln && ln["otp_verification"]
            ? ln["otp_verification"]
            : "OTP Verification"}
        </Typography>
        <Button
          className={classes.closeButton}
          variant="text"
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </Button>
        <div className={classes.OtpFormContainer}>
          <Typography variant="h6" className={classes.formLable}>
            {ln && ln["enter_otp_we_have_sent_to"]
              ? ln["enter_otp_we_have_sent_to"]
              : "Enter OTP we have sent to"}{" "}
            +91 {otp_data?.phone}
          </Typography>
          <Typography variant="h6" className={classes.formLable}>
            Otp: {otp_data?.otp}
          </Typography>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle={classes.otpInputContainer}
            inputStyle={classes.otpInput}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
          <div className={classes.otpResendDiv}>
            <Typography variant="p" className={classes.SecondsText}>
              {ln && ln["waiting_for_otp"]
                ? ln["waiting_for_otp"]
                : "waiting for OTP"}
              ....{time}sec
            </Typography>
            <Button
              variant="text"
              className={classes.resendOtpBtn}
              onClick={() => handleResendOtp()}
              disabled={disable}
            >
              {ln && ln["resend_otp"] ? ln["resend_otp"] : "Resend OTP"}
            </Button>
          </div>
        </div>
        <Button
          onClick={() => handleSubmit()}
          variant="contained"
          fullWidth
          className={`mt-4 ${classes.continueBtn}`}
        >
          {ln && ln["verify"] ? ln["verify"] : "Verify"}
        </Button>
        <Button
          variant="outlined"
          fullWidth
          className={`mt-2 ${classes.whatsAppBtn}`}
        >
          <WhatsappIcon />
          <span>
            {ln && ln["resend_on_whatsapp"]
              ? ln["resend_on_whatsapp"]
              : "Resend On Whatsapp"}
          </span>
        </Button>
      </Card>
    );
  };
  return (
    <div>
      <DefaultLayout children={<OtpVerificationDiv />} />
      {errorAlert && defaultError && (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default OtpVerification;
