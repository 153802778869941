import { Box, Button, Container, Grid, Typography } from "@mui/material";
import classes from "./others.module.css";
import {
  ContactFbIcon,
  ContactInstaIcon,
  ContactLinkedIcon,
  ContactLocationIcon,
  ContactMailIcon,
  ContactPhoneIcon,
  ContactTwitterIcon,
  WhiteDownArrowIcon,
} from "../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect, useState } from "react";
import Dropdown from "../Home/Dropdown";
import { addContactUs } from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";

function ConnectWithUs() {
  const [contactSuccess, setContactSuccess] = useState(false);
  const [contactFailure, setContactFailure] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    message: "",
    email: "",
    name: "",
    phone: "",
  });
  const { ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleChange(e) {
    const { value, name } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  function handleAddContact() {
    if (contactInfo.email === "-1") {
      
    } else {
      let body = { ...contactInfo };
      dispatch(addContactUs(body, setContactSuccess,setContactFailure,"form"));
    }
  }

  useEffect(() => {
    if (contactSuccess) {
      setContactSuccess(false);
      setContactFailure(false);
      setContactInfo({
        message: "",
        email: "",
        name: "",
        phone: "",
      });
    }
  }, [contactSuccess]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box bgcolor={"#f8f8f8"} pb={4}>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
         <Dropdown />
      </Box>
      <Container>
        <Box bgcolor={"#fff"} className={classes.connect_us_part}>
          <Typography className={classes.connect_us}>
            Connect with Us
          </Typography>
          <Box py={6} width={"85%"} margin={"auto"}>
            <Grid container>
              <Grid item xs={12} sm={12} md={7}>
                <Box mb={4}>
                  <h5 style={{ fontWeight: "600" }}>Bussiness Hours:</h5>
                  <Typography mb={4}>
                    Our support team is available to assist you during the
                    following hours:
                  </Typography>
                  <Typography mb={1}>
                    Monday to Friday: 9:00 AM - 6:00 PM (Local Time)
                  </Typography>
                  <Typography mb={1}>
                    Saturday: 10:00 AM - 4:00 PM (Local Time)
                  </Typography>
                  <Typography mb={1}>Sunday: Closed</Typography>
                </Box>
                <Box mb={4}>
                  <h5 style={{ fontWeight: "600" }}>Contact information:</h5>
                  <Typography mb={1} mt={2}>
                    <ContactMailIcon /> support@yourapp.com
                  </Typography>
                  <Typography mb={1}>
                    <ContactPhoneIcon /> +1 (123) 456-7890
                  </Typography>
                  <Typography mb={1}>
                    <ContactLocationIcon /> 123 Main Street, City, State, Zip
                    Code, Country
                  </Typography>
                </Box>
                <Box>
                  <h5 style={{ fontWeight: "600" }}>Social media</h5>
                  <Typography mb={2}>
                    Stay connected with us on social media for updates, tips,
                    and special offers:
                  </Typography>
                  <Typography>
                    <ContactTwitterIcon />
                    <ContactInstaIcon />
                    <ContactFbIcon />
                    <ContactLinkedIcon />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box className={classes.connect_details} mt={{xs:5,sm:5,md:0}} >
                    <Typography className={classes.connect_details_title}>Get in Touch</Typography>
                    <Typography>We are here, How can we help?</Typography>
                    <ValidatorForm
                  useref="form"
                    onSubmit={handleAddContact}
                  className={classes.SignUpForm}
                >
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your name"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"name"}
                    value={contactInfo.name}
                    size="normal"
                    onChange={handleChange}
                    style={{ margin: "12px 0" }}
                    validators={["required"]} // Add this line
                    errorMessages={["Your name is required"]}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your email (optional)"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"email"}
                    value={contactInfo.email}
                    size="normal"
                    onChange={handleChange}
                    style={{ margin: "12px 0" }}
                  />
                   <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Enter your phone"
                  className={`${classes.formInputFeild} my-field-two`}
                  name="phone"
                    value={contactInfo.phone}
                  size="normal"
                  onChange={handleChange}
                  style={{ margin: "12px 0" }}
                  validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                />
                  <TextValidator
                  variant="outlined"
                  multiline
                  rows={2}
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Type your message here"
                  className={`${classes.formInputFeild} my-field-two`}
                  name="message"
                    value={contactInfo.message}
                  size="normal"
                  onChange={handleChange}
                  style={{ margin: "12px 0" }}
                  validators={["required"]} // Add this line
                  errorMessages={["Details is required"]}
                />
                  <Box mt={2}>
                    <Button
                      fullWidth
                      className={classes.register_btn}
                      type="submit"
                    //   onClick={handleContactOwner}
                    //   disabled={!isOwnerChecked}
                    >
                      Submit
                    </Button>
                  </Box>
                </ValidatorForm>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {contactFailure && (
          <AlertBox type={"error"} message={"Something went wrong. Try again."} stateName={()=>setContactFailure(false)} />
        )}
    </Box>
  );
}

export default ConnectWithUs;
