import { useEffect, useState } from "react";
import Navbar from "../Pages/Home/Navbar";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { successAlertAction } from "../Redux/Actions/userActions";
import SnackBarPop from "../Pages/popup/SnackBarPop";
import FooterSection from "../Pages/Home/FooterSection";

function HomeLayout() {
  const [snackBar, setSnackBar] = useState({
    snack: false,
    snackMsg: "",
    vertical: "top",
    horizontal: "right",
  });
  const { snack, snackMsg, vertical, horizontal } = snackBar;
  const navigate = useNavigate();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  const { successAlert } = useSelector((state) => state.userLogin);
  let status = localStorage.getItem("success");

  const handleClose = () => {
    setSnackBar({
      snack: false,
      snackMsg: "",
      vertical: "top",
      horizontal: "right",
    });
  };

  useEffect(() => {
    let user_path = window.location.pathname;

    if (
      !_token &&
      !user_path.includes("homepage") &&
      !user_path.includes("results") &&
      !user_path.includes("plan") &&
      !user_path.includes("privacy") &&
      !user_path.includes("connect") &&
      !user_path.includes("terms") &&
      !user_path.includes("services") &&
      !user_path.includes("news") &&
      !user_path.includes("singleproperty") &&
      !user_path.includes("news") &&
      !user_path.includes("about") 
    ) {
      navigate("/login");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (status === "1") {
      localStorage.removeItem("success");
      setSnackBar({ ...snackBar, snackMsg: successAlert, snack: true });
    }
  }, [status]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <Outlet />
      <FooterSection />
      <SnackBarPop open={snack} handleClose={handleClose} snackMsg={snackMsg} />
    </>
  );
}

export default HomeLayout;
