import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
} from "@mui/material";
import classes from "../../Layout/layout.module.css";
import classes1 from "./property.module.css";
import Logo from "../../Assets/Images/gazamLogo.png";
import { MenuIcon, PhoneIcon } from "../../Assets/Icons/icons";
import { useEffect, useState } from "react";
import "./property.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { errorAlertAction, successAlertAction } from "../../Redux/Actions/userActions";
import PropertyHeader from "./PropertyHeader";
import { Navigate, useNavigate} from "react-router-dom";
import Navbar from "../Home/Navbar";
import ContactPop from "../popup/ContactPop";

function PropertyLayout({ children, stepper }) {
  const [activeStep, setActiveStep] = useState(stepper);
  const [contactPopup, setContactPopup] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "8756737567",
    email: "n/a",
    name: "Admin",
  });
  const { errorAlert,successAlert,ln } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;

  const steps = [
    {
      label:   ln && ln["add_basic_details"] ? ln["add_basic_details"] : "Add Basic Details",
      description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
    },
    {
      label:  ln && ln["add_property_details"] ? ln["add_property_details"] : "Add Property Details" ,
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
    },
    {
      label:   ln && ln["add_photos_pricing"] ? ln["add_photos_pricing"] : "Add Photo and Pricing",
      description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(()=>{
    let status = localStorage.getItem("error");
    
    if (errorAlert && !errorAlert.includes("setUserValues ") && status === "2" && !errorAlert.includes("function") ) {
      localStorage.removeItem("error")
      toast.error(errorAlert,{
        onClose: () => dispatch(errorAlertAction("")),
        autoClose:3000
      })
    }
  },[errorAlert]);

  useEffect(()=>{
    let status = localStorage.getItem("success");
    if (successAlert && successAlert.length > 0 && status === "1") {
      localStorage.removeItem("success")
      toast.success(successAlert,{
        onClose: () => dispatch(successAlertAction("")),
        autoClose:2000
      })
    }
  },[successAlert]);

  useEffect(()=>{
    let user_path = window.location.pathname;

    if (!_token && !user_path.includes("postproperty") && !user_path.includes("propertybasicdetails") && !user_path.includes("propertyregister") && !user_path.includes("propertyregisterverify")) {
      navigate("/login")
    } 
  },[window.location.pathname])

  return (
    <>
      <Box>
        
          {/* <PropertyHeader /> */}
        <Navbar />
      </Box>
      <Box className={classes.property_layout}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography className={classes.header}>
                
                {ln && ln["upload_your_property"] ? ln["upload_your_property"] : "Upload Your Property in 3 Easy Steps"}
                {" "}
              </Typography>
              <Box className="stepper">
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel className={classes1.step_label}>
                        {step.label}
                      </StepLabel>
                      {/* <StepContent>
                        <Typography>{step.description}</Typography>
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Continue"}
                            </Button>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Back
                            </Button>
                          </div>
                        </Box>
                      </StepContent> */}
                    </Step>
                  ))}
                </Stepper>
                {/* {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Reset
                    </Button>
                  </Paper>
                )} */}
              </Box>
              <Box className="d-flex mt-5" flexWrap={"wrap"} >
                <Typography className={classes1.help_text} >
                    {ln && ln["dont_want_to_fill_all_the_details"] ? ln["dont_want_to_fill_all_the_details"] : "Don’t want to fill all the details?" }<br />
                
                  {ln && ln["call_us_to_help_you"] ? ln["call_us_to_help_you"] : "Call us to help you" }
                </Typography>
                <Typography className={classes1.help_number} mt={{xs:2,sm:2,md:0}} onClick={()=>setContactPopup(true)}  >
                  <PhoneIcon />   {ln && ln["call_for_assistance"] ? ln["call_for_assistance"] : "Call for assistance" }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={5}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Box>
      { true &&
      <ToastContainer />
      }
      <ContactPop
          open={contactPopup}
          handleClose={()=>setContactPopup(false)}
          contactPopupDetail={contactPopupDetail}
          // handleGetContact={handleGetContact}
          // getContact={getContact}
        />
    </>
  );
}

export default PropertyLayout;
