import { PlanCheckIcon, PlanUnCheckIcon } from "../../Assets/Icons/icons";
import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import classes from "./home.module.css";
import PlanBg from "../../Assets/Images/plan-bg.png";
import { useSelector } from "react-redux";
let my_plans = [
  {
    name: "3 month plan validity",
    check: true,
  },
  {
    name: "3 month plan validity",
    check: true,
  },
  {
    name: "3 month plan validity",
    check: false,
  },
  {
    name: "3 month plan validity",
    check: true,
  },
  {
    name: "3 month plan validity",
    check: true,
  },
  {
    name: "3 month plan validity",
    check: false,
  },
  {
    name: "3 month plan validity",
    check: true,
  },
];

function Plans() {
  const [activePlan, setActivePlan] = useState("basic");
  const { ln, error } = useSelector((state) => state.userLogin);
  return (
    <>
      <Container>
        <Box mb={4} mt={3}>
          <Typography
            fontWeight={"600"}
            fontSize={{ xs: "21px", sm: "25px", md: "25px" }}
          >
            {ln && ln["get_upto_10X_responses_and_sell_faster"]
              ? ln["get_upto_10X_responses_and_sell_faster"]
              : " Get upto 10X Responses and sell faster"}
          </Typography>
          <span>
            {" "}
            {ln &&
            ln["do_not_miss_out_on_responses_with_your_current_free_plan"]
              ? ln["do_not_miss_out_on_responses_with_your_current_free_plan"]
              : " Do not miss out on responses with your current free plan"}
          </span>
        </Box>
        <Box width={"75%"} margin={"auto"}>
        <Grid container spacing={2}>
          {/* <Grid item xs={6} sm={6} md={4}>
            <Box
              className={
                activePlan === "free"
                  ? classes.plan_card_active
                  : classes.plan_card
              }
              onClick={() => setActivePlan("free")}
            >
              <Box
                height={"50px"}
                width={"100%"}
                mb={5}
                className="d-flex-center"
              >
                <Typography
                  position={"relative"}
                  className="d-flex-center"
                  sx={{
                    backgroundImage: activePlan === "free" && `url(${PlanBg})`,
                    width: "58%",
                    height: "30px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <span style={{ color: activePlan === "free" && "#fff" }}>
                    {ln && ln["free"] ? ln["free"] : "Free"}
                  </span>
                </Typography>
              </Box>
             <Box display={{xs:"none",sm:"block",md:"block"}} >
             <Typography>
                {ln && ln["benefits"] ? ln["benefits"] : "Benefits"}
              </Typography>
              <Box>
                {my_plans.map((datas) => (
                  <Typography className={classes.single_plan_info}>
                    {datas.check ? <PlanCheckIcon /> : <PlanUnCheckIcon />}
                    {datas.name}
                  </Typography>
                ))}
              </Box>
             </Box>
              <Box height={"40px"} mt={5} className="d-flex-center">
                <Button className={classes.plan_subscribe}>
                  {ln && ln["subscribe"] ? ln["subscribe"] : "Subscribe"}
                </Button>
              </Box>
            </Box>
          </Grid> */}
          <Grid item xs={6} sm={6} md={4}>
            <Box
              className={`${
                activePlan === "basic"
                  ? classes.plan_card_active
                  : classes.plan_card} 
                  ${classes.hover_plans}
              `}
              onClick={() => setActivePlan("basic")}
            >
              <Box
                height={"50px"}
                width={"100%"}
                mb={5}
                className="d-flex-center"
              >
                <Typography
                  position={"relative"}
                  className="d-flex-center"
                  sx={{
                    backgroundImage: activePlan === "basic" && `url(${PlanBg})`,
                    width: "58%",
                    height: "30px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <span style={{ color: activePlan === "basic" && "#fff" }}>
                    {ln && ln["basic"] ? ln["basic"] : "Basic"}
                  </span>
                </Typography>
              </Box>
              <Box display={{xs:"none",sm:"block",md:"block"}} >
              <Typography>
                {ln && ln["benefits"] ? ln["benefits"] : "Benefits"}
              </Typography>
              <Box>
                {my_plans.map((datas) => (
                  <Typography className={classes.single_plan_info}>
                    {datas.check ? <PlanCheckIcon /> : <PlanUnCheckIcon />}
                    {datas.name}
                  </Typography>
                ))}
              </Box>
              </Box>
              <Box height={"40px"} mt={5} className="d-flex-center">
                <Typography>₹7499/-</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Box
              className={`${
                activePlan === "standard"
                  ? classes.plan_card_active
                  : classes.plan_card }
                  ${classes.hover_plans}
              `}
              onClick={() => setActivePlan("standard")}
            >
              <Box
                height={"50px"}
                width={"100%"}
                mb={5}
                className="d-flex-center"
              >
                <Typography
                  position={"relative"}
                  className="d-flex-center"
                  sx={{
                    backgroundImage:
                      activePlan === "standard" && `url(${PlanBg})`,
                    width: "58%",
                    height: "30px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <span style={{ color: activePlan === "standard" && "#fff" }}>
                    {ln && ln["standard"] ? ln["standard"] : "Standard"}
                  </span>
                </Typography>
              </Box>
              <Box display={{xs:"none",sm:"block",md:"block"}} >
              <Typography>
                {ln && ln["benefits"] ? ln["benefits"] : "Benefits"}
              </Typography>
              <Box>
                {my_plans.map((datas) => (
                  <Typography className={classes.single_plan_info}>
                    {datas.check ? <PlanCheckIcon /> : <PlanUnCheckIcon />}
                    {datas.name}
                  </Typography>
                ))}
              </Box>
              </Box>
              <Box height={"40px"} mt={5} className="d-flex-center">
                <Typography>₹10999/-</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Box
              className={`${
                activePlan === "premium"
                  ? classes.plan_card_active
                  : classes.plan_card}
                  ${classes.hover_plans}
              `}
              onClick={() => setActivePlan("premium")}
            >
              <Box
                height={"50px"}
                width={"100%"}
                mb={5}
                className="d-flex-center"
              >
                <Typography
                  position={"relative"}
                  className="d-flex-center"
                  sx={{
                    backgroundImage:
                      activePlan === "premium" && `url(${PlanBg})`,
                    width: "58%",
                    height: "30px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <span style={{ color: activePlan === "premium" && "#fff" }}>
                    {ln && ln["premium"] ? ln["premium"] : "Premium"}
                  </span>
                </Typography>
              </Box>
              <Box display={{xs:"none",sm:"block",md:"block"}} >
              <Typography>
                {ln && ln["benefits"] ? ln["benefits"] : "Benefits"}
              </Typography>
              <Box>
                {my_plans.map((datas) => (
                  <Typography className={classes.single_plan_info}>
                    {datas.check ? <PlanCheckIcon /> : <PlanUnCheckIcon />}
                    {datas.name}
                  </Typography>
                ))}
              </Box>
              </Box>
              <Box height={"40px"} mt={5} className="d-flex-center">
                <Typography>₹20999/-</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box display={{xs:"block",sm:"none",md:"none"}} padding={"21px"} >
              <Typography fontSize={"24px"} >
                {ln && ln["benefits"] ? ln["benefits"] : "Benefits"}
              </Typography>
              <Box>
                {my_plans.map((datas) => (
                  <Typography className={classes.single_plan_info} fontSize={"20px"}>
                    {datas.check ? <PlanCheckIcon /> : <PlanUnCheckIcon />}
                    {datas.name}
                  </Typography>
                ))}
              </Box>
              </Box>
        </Box>
      </Container>
    </>
  );
}

export default Plans;
